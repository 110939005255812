import React, { useState } from 'react';
import {Modal, Tab, Row, Col,Nav} from 'react-bootstrap'

import Scrollbars from 'react-custom-scrollbars';



const AccReferral = (props) =>{
    const[referral, setReferral] = useState(true)


   
    return(
        <>
        <Modal show={referral} scrollable centered size="md" className='modal_style' id="firstmodal">
           <Modal.Header className='border-0 pb-0'>
               <button type="button" className="btn close close_modal" onClick={() => props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
          </Modal.Header>
          <Modal.Body className=''>  
               <div className='insight-tab'>
                <h4> People you’ve invited</h4>
                <p className='text-muted'>The travel credit you earn can be used to book your next home or experience on JetToLet.</p>
             
               <Tab.Container className="" id="left-tabs-example" defaultActiveKey="pending">
          
          <Row className='d-flex align-items-center justify-content-between flex-column'>
              <Col className="mx-auto bg-white">
                  <Nav variant="pills" className="">
                      <Nav.Item><Nav.Link eventKey="pending">Pending</Nav.Link></Nav.Item>
                      <Nav.Item><Nav.Link eventKey="available">Available</Nav.Link></Nav.Item>
                     
                  </Nav>
              </Col>
              </Row>

          <Tab.Content>
              <Tab.Pane eventKey="pending">
                <div className='pendings mt-4'>
                    <p className='credit f-50'><b>$0</b></p>
                    <label className='text-muted'>Pending credits</label>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="available">
                <div className='availables mt-4'>
                    <p className='credit f-50'><b>$0</b></p>
                    <label className='text-muted'>Available credits</label>
                </div>
                
             </Tab.Pane>
          </Tab.Content>
  
                </Tab.Container>

              </div>

          </Modal.Body>
      </Modal>
      </>
    )
}

export default AccReferral