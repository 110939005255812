import React, { useState, useEffect } from "react";
import {
  Navbar,
  NavDropdown,
  Nav,
  Container,
  Offcanvas,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import user from "../../../Assets/images/user.png";
import Scrollbars from "react-custom-scrollbars";
import logo from "../../../Assets/images/landingPage/flutterpadlogo.png";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { getunreadnotificationhooks, getUserDatas, getUserEmail, getusersdatahooks, readallhooks, readonehooks, switchhostandguesthooks, userLogout } from "../../../Hooks/useUserHook";
import { API_URL } from "../../../config/env";
import Login from "../../Common/Modals/LoginModal";
import { toast } from "react-hot-toast";
import { io } from 'socket.io-client';


const CustomFilterHeader = (props) => {
  const [userDatas, setUserDatas] = useState({});
  const [email , setEmail] = useState("");
  const [showlog, setShowLog] = useState(false);
  const [unread , setUnread] = useState([]);
  const [key , setKey] = useState("");


  const navigate = useNavigate();
  // const socket = io(API_URL);

  const getnotification = async() =>{
      let user = getUserDatas()
      let notifydata = await getunreadnotificationhooks(user?._id)
      console.log("notifydata" , notifydata);
      setUnread(notifydata?.data?.data);
  }

  const handlereadone = async(id) => {
    let user = getUserDatas()
    let readone = await readonehooks(id)
    let refresh = await getnotification();
  }
  const handlereadall = async() => {
    let user = getUserDatas()
    let readone = await readallhooks(user?._id)
    let refresh = await getnotification();
  }

  useEffect(()=>{
    async function fetchdata(){
      await getnotification()
    }
    fetchdata();
  } , [])

  // useEffect(()=>{
  //   socket.on("getMessage" , ()=>{
  //      getnotification()
  //   })
  // } , [socket])

  const logout = () => {
    userLogout().then((data) => {
      console.log("data<<<<<<", data, data.status);
      if (data.status) {
        console.log("data.emialllllll", data.useremail);
        // navigate(`/${data.useremail}`);
        navigate("/");
      }
    });
  };

  const checkUserLogin = async (type) => {
    console.log("check user login :", email , userDatas);
    if (email) {
      if(userDatas?.govtproof?.length > 0 && userDatas?.govtproof[0]?.verification == "accepted"){
        if(parseFloat(userDatas?.hostsubscribedaycount) > 0){
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`
          } else if (type == "experience") {
            window.location.href = window.location.origin + `/host-experience/${email}`
          }
        }
        else{
          toast.error("Need Host Subscription!..");
          window.location.href = window.location.origin + `/account`
        }
    }
    else{
      toast.error("Upload your government id!");
      window.location.href = window.location.origin + `/account`
    }
    } else {
      setShowLog(true);
    }
  }


  const hostDashboard = async () => {
    if (email) {
      window.location.href = window.location.origin + `/host-dashboard/${email}`
    } else {
      setShowLog(true);
    }
  }

  

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    // setUserDatas(getUserDatas());
    async function fetchdata(){
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      setUserDatas(userdata);
    }
    fetchdata();
  }, []);

  return (
    <>
    {showlog && <Login logs = {key} onDismiss={() => setShowLog(false)} />}
      <Navbar
        bg="light"
        expand="false"
        className="mb-3 d-md-none d-block hosthead "
      >
        <Container fluid>
          <Link to="/">
          <Navbar.Brand>
            <img src={logo} alt="" className="brand_log_sm" />
          </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`} />
            </Offcanvas.Header>
            <Offcanvas.Body className="mobilehead">
              <Nav className="justify-content-end flex-grow-1 pe-0">
                <NavDropdown
                  title="INR"
                //   id={`offcanvasNavbarDropdown-expand-false`}
                >
                  <NavDropdown.Item onClick={() => { checkUserLogin("room") }}>
                    Host a Room
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item onClick={() => { checkUserLogin("experience") }}>
                    Host an Experience
                  </NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown
                  title="Become a Host"
                //   id={`offcanvasNavbarDropdown-expand-false`}
                >
                  <NavDropdown.Item onClick={() => { checkUserLogin("room") }}>
                    Host a Room
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item onClick={() => { checkUserLogin("experience") }}>
                    Host an Experience
                  </NavDropdown.Item> */}
                </NavDropdown>
              </Nav>

              <div className="d-flex rightheader mob_rigt_je">
              <div className="not_wih_o">
              {unread?.length > 0 && <span className="icon_not_count">{unread?.length}</span>}
                <NavDropdown
                  title=""
                  id="navbarScrollingDropdown"
                  className="themedrop bell me-3 "
                >
                    <h6 className="mb-0 p-3">Notification</h6>
                  <div className="text-right">
                  <a onClick={async() => await handlereadall()} className="noti_new_link">Read All</a>
                  </div>
                  <Scrollbars style={{ width: "300px", height: "250px" }}>
                    <div className="not-cont notificationss">
                    <ul className="ps-0">
                    {unread?.map((data , i) =>
<>
                      <li className="d-flex">
                        <div>
                          {/* <img
                            src={
                              userDatas && userDatas.profilePicturePath
                                ? API_URL + userDatas.profilePicturePath
                                : user
                            }
                            className="me-1"
                            alt=""
                          /> */}
                        </div>

                        <p className="mb-0" onClick={() =>{
                        if(data?.description == "You have one new messsage !"){
                          navigate(`/host-dashboard/inbox/${userDatas?.email}`)
                        }
                      }}>
                          {data?.description}
                        </p>
                      </li>
                      <hr />
                      </>
                    )}

                    </ul>
                  </div>
                  </Scrollbars>
                  <div className="text-center">

                  <Link  to="/notification" className="noti_new_link">Show All Notifications</Link>
                    </div>
                </NavDropdown>
                </div>
                <NavDropdown
                  title={
                    <img
                      src={
                        userDatas && userDatas.profilePicturePath
                          ? API_URL + userDatas.profilePicturePath
                          : user
                      }
                      alt=""
                    />
                  }
                  id="navbarScrollingDropdown"
                  className="themedrop hideafter"
                >
                  <NavDropdown.Item href="/account/profile">
                    Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/account"> Account</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#action5"> Get Help </NavDropdown.Item> */}
                  {/* <NavDropdown.Item href="#action6"> Language and Trasaction</NavDropdown.Item> */}
                  {/* <NavDropdown.Item href="#action7">INR </NavDropdown.Item> */}
                  {/* <NavDropdown.Item
                    href={`/host-experience/${userDatas && userDatas.email}`}
                  >
                    Host an Experience
                  </NavDropdown.Item> */}
                  {userDatas && userDatas.email &&<Dropdown.Item onClick={async() => {
                          let payload = {
                            id : userDatas?._id,
                            host : !userDatas?.isHost
                          }
                          let res = await switchhostandguesthooks(payload);
                          let up = await getusersdatahooks(getUserEmail());
                          setTimeout(window.location.reload() , 500);
                        }}>
                  Switch To {userDatas?.isHost == true ? "Travelling" : "Hosting"}
                </Dropdown.Item>}
                  <NavDropdown.Item href="#action10"> Log out</NavDropdown.Item>
                </NavDropdown>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      <Navbar bg="light" expand="md" className="hosthead d-md-block d-none">
        <Container fluid>
          <Navbar.Brand href={window.location.origin + "/"}>
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse
            id="navbarScroll"
            className="d-fex justify-content-end"
          >
            {/* <Nav
              className="m-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <NavLink
                className="nav-link "
                to={`/host-dashboard/${userDatas && userDatas.email}`}
              >
                Today
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/host-dashboard/inbox/${userDatas && userDatas.email}`}
              >
                Inbox
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/host-dashboard/calendar/${userDatas && userDatas.email}`}
              >
                Calendar
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/host-dashboard/listings/${userDatas && userDatas.email}`}
              >
                Listings
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/host-dashboard/dispute/${userDatas && userDatas.email}`}
              >
                Dispute
              </NavLink>
              <NavLink
                className="nav-link"
                to={`/host-dashboard/trips/trippayment/${userDatas &&
                  userDatas.email}`}
              >
                Earning
              </NavLink>

              <NavDropdown title="Trip" id="basic-nav-dropdown">
                <NavLink
                  to={`/host-dashboard/trips/stay/${userDatas &&
                    userDatas.email}`}
                  className="dropdown-item"
                  href="#action/3.1"
                >
                  Stay
                </NavLink>
                <NavLink
                  to={`/host-dashboard/trips/experience/${userDatas &&
                    userDatas.email}`}
                  className="dropdown-item"
                  href="#action/3.1"
                >
                  Experience
                </NavLink>
              </NavDropdown>
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="become-a-host-header"
                >
                  Become a Host
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Host a Room</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Host an Experience
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav> */}
            <div className="d-flex rightheader">
              {/* <NavDropdown
                title="INR"
                id="navbarScrollingDropdown"
                className="me-3 custom-navbar"
              >
                <NavDropdown.Item href="#action3">USD</NavDropdown.Item>
                <NavDropdown.Item href="#action3">SG</NavDropdown.Item>
              </NavDropdown> */}
              <NavDropdown
                title="Become a Host"
                id="navbarScrollingDropdown"
                className="themedrop me-3 custom-navbar"
              >
                <NavDropdown.Item onClick={() => { checkUserLogin("room") }}>Host a Room</NavDropdown.Item>
                {/* <NavDropdown.Item onClick={() => { checkUserLogin("experience") }}>
                  Host an Experience
                </NavDropdown.Item> */}
              </NavDropdown>
              <div className="not_wih_o">
            {unread?.length > 0 && <span className="icon_not_count">{unread?.length}</span>}
              <NavDropdown
                title=""
                id="navbarScrollingDropdown"
                className="themedrop bell me-3"
              >
                  <h6 className="mb-0 p-3">Notification</h6>
                
      <div className="text-right">
        <a onClick={async() => await handlereadall()} className="noti_new_link">Read All</a>
        </div>
                <Scrollbars style={{ width: "300px", height: "250px" }}>
                  <div className="not-cont notificationss">
                    <ul className="ps-0">
                      {unread?.map((data , i) => 
                      <>
                      <li className="d-flex">
                      {/* <div>
                        <img
                          src={
                            userDatas && userDatas.profilePicturePath
                              ? API_URL + userDatas.profilePicturePath
                              : user
                          }
                          className="me-1"
                          alt=""
                        />
                      </div> */}

                      <p className="mb-0" onClick={() =>{
                        if(data?.description == "You have one new messsage !"){
                          navigate(`/host-dashboard/inbox/${userDatas?.email}`)
                        }
                      }}>
                        {data?.description}
                      </p>
                    </li>
                    <hr />
                    </>
                      )}
                    </ul>
                  </div>
                </Scrollbars>

                <div className="text-center">

    <Link to="/notification" className="noti_new_link">Show All Notifications</Link>
      </div>
              </NavDropdown>
              </div>
              <NavDropdown
                title={
                  <img
                    src={
                      userDatas && userDatas.profilePicturePath
                        ? API_URL + userDatas.profilePicturePath
                        : user
                    }
                    alt=""
                  />
                }
                id="navbarScrollingDropdown"
                className="themedrop hideafter"
              >
              
                {(userDatas && userDatas?.email) && <NavDropdown.Item onClick={() => hostDashboard()}>
                  Dashboard
                </NavDropdown.Item>}
                {(userDatas && userDatas?.email) && <NavDropdown.Item href="/account">
                  Account
                </NavDropdown.Item>}
               {(userDatas && userDatas?.email) && <NavDropdown.Item href = "/wishlist">
               Saved Listings 
                </NavDropdown.Item>}
                {userDatas && userDatas.email &&  <NavDropdown.Item href="/subscriptions-list">
                      Subscriptions
                    </NavDropdown.Item>}
                {userDatas && userDatas.email &&<NavDropdown.Item onClick={async() => {
                          let payload = {
                            id : userDatas?._id,
                            host : !userDatas?.isHost
                          }
                          let res = await switchhostandguesthooks(payload);
                          let up = await getusersdatahooks(getUserEmail());
                          setTimeout(window.location.reload() , 500);
                        }}>
                  Switch To {userDatas?.isHost == true ? "Travelling" : "Hosting"}
                </NavDropdown.Item>}
                {/* <NavDropdown.Item onClick={() => { (userDatas && userDatas?.email) ? logout() : setShowLog(true) }}>
                {(userDatas && userDatas.email) ? "Logout" : "Signup/Login"}
                </NavDropdown.Item> */}

                      {!userDatas?.email ? 
                        <>
                        <NavDropdown.Item onClick={() => {setKey(""); setShowLog(true) }}>Signup</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => {setKey("login") ; setShowLog(true) }}>Login</NavDropdown.Item>
                        </>: <NavDropdown.Item onClick={() => {logout()}}>Logout</NavDropdown.Item>
                      }
              </NavDropdown>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomFilterHeader;
