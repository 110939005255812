import React from "react";
import HostHeader from "../BecomeHost/AfterHost/HostHeader";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { getallcmsdatahook } from "../../Hooks/useUserHook";
// Import images

import aboutBanner from "../../Assets/images/About/1.png";
import dreamImg from "../../Assets/images/About/2.jpg";
import workplaceImg from "../../Assets/images/About/3.jpg";
import { Link } from "react-router-dom";
import { API_URL } from "../../config/env";
import Header from "./Header";

const Privacypolicy = () => {
  const [cms, setCms] = useState([]);
  const [content, setContent] = useState({})
 
  useEffect(() => {
    async function fetchdata() {
      console.log("");
      await getallcmsdatahook().then((result) => {
        console.log("get all crm hook result", result?.data?.data);
        result?.data?.data?.map((data, i) => (data.sno = i + 1));
        setCms(result?.data?.data);
        let filterdata = result?.data?.data?.find(
          (el) => el.name == "privacypolicy" && el?.status == "Active"
        );
        console.log("filterdata_filterdata", filterdata,filterdata?.content, filterdata?.updatedAt);
        setContent(filterdata?.content)
      });
    }

    fetchdata();
  }, [])



  return cms && cms != 0 && content ? (
    <>
      <HostHeader />

      {/* Privacy Policy */}

      <section className="pad_y cms_cmn_style">
        <div className="container" dangerouslySetInnerHTML={{__html:content}}>

       
        </div>
      </section>
      <Footer />
    </>
  ) : (
    <></>
  );
};

export default Privacypolicy;


{/* <section className="pad_y">
<div className="container">
  <div className="row text-center mb-3">
    <p className="policy_head mb-2">JetToLet</p>
    <p className="policy_tle">PRIVACY POLICY</p>
  </div>
  <div className="row">
    <p className="mb-2 bold_txt_sm  ">Last Updated: 9/2023</p>
    <p className="policy_head mb-3">Thank you for using JetToLet!</p>
  </div>

  <div className="row mt-3 mt-md-5  mb-5">
    <div className="col-lg-6 d-flex align-items-center">
      <img src={bannerImg} alt="banner img" className="img-fluid" />
    </div>
    <div className="col-lg-6 px-md-4 px-lg-5 d-flex flex-column justify-content-center mt-4 mt-sm-5 mt-lg-0">
      <p class="grey_p_txt mb-3">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea modi
        expedita eos natus reprehenderit dolorum distinctio repudiandae
        eaque ipsa error! Voluptatem temporibus aliquid excepturi!
        Laudantium eveniet vel recusandae dignissimos reiciendis minima
        nemo commodi veritatis eos perferendis! Magni veritatis ratione
        fugit labore non voluptate, eveniet eius assumenda optio
        dolores. Numquam modi doloribus, veniam placeat repudiandae amet
        similique pariatur ab harum. At tempore consequuntur sed error,
        quia ipsam reiciendis placeat libero modi?
      </p>
      <p class="grey_p_txt mb-3">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
        deserunt mollitia delectus assumenda error consequuntur
        blanditiis. Quidem tempora atque possimus asperiores. Nobis,
        culpa! Minima distinctio in repudiandae numquam ipsam quis.
      </p>
    </div>
  </div>

  <h3 class="policy_tle mb-3">1. PERSONAL DATA WE COLLECT</h3>

  <div>
    <p className="grey_p_txt mb-3">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci
      neque culpa eos nihil dolor aliquam? Iusto, totam magni? Deleniti,
      sapiente?
    </p>
    <p className="grey_txt_bold mb-2">Personal Data You Provide:</p>
    <ul className="">
      <li>
        <p className="grey_p_txt mb-3">
          <span className="grey_txt_bold">- Registration. </span>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
          atque neque consequuntur dolores amet, molestias nam in nihil
          architecto, excepturi ducimus! Qui non odio laudantium
          accusantium provident est, ad perferendis consequuntur
          repellendus facere aperiam. Dignissimos vitae ea error earum,
          doloribus veritatis facere amet ipsa hic veniam repellendus
          nostrum inventore saepe!
        </p>
        <p className="grey_p_txt mb-3">
          <span className="grey_txt_bold">- Identification. </span>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
          atque neque consequuntur dolores amet, molestias nam in nihil
          architecto, excepturi ducimus! Qui non odio laudantium
          accusantium provident est, ad perferendis consequuntur
          repellendus facere aperiam. Dignissimos vitae ea error earum,
          doloribus veritatis facere amet ipsa hic veniam repellendus
          nostrum inventore saepe!
        </p>
        <p className="grey_p_txt mb-3">
          <span className="grey_txt_bold">- Communications. </span>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
          atque neque consequuntur dolores amet, molestias nam in nihil
          architecto, excepturi ducimus! Qui non odio laudantium
          accusantium provident est, ad perferendis consequuntur
          repellendus facere aperiam. Dignissimos vitae ea error earum,
          doloribus veritatis facere amet ipsa hic veniam repellendus
          nostrum inventore saepe!
        </p>
      </li>
    </ul>
  </div>
  <h3 class="policy_tle mb-3">
    2.PERSONAL DATA WE COLLECT FROM THIRD PARTIES:
  </h3>
  <ul>
    <li>
      <p className="grey_p_txt mb-3">
        <span className="grey_txt_bold">- Background Checks. </span>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
        atque neque consequuntur dolores amet, molestias nam in nihil
        architecto, excepturi ducimus! Qui non odio laudantium
        accusantium provident est, ad perferendis consequuntur
        repellendus facere aperiam. Dignissimos vitae ea error earum,
        doloribus veritatis facere amet ipsa hic veniam repellendus
        nostrum inventore saepe!
      </p>
      <p className="grey_p_txt mb-3">
        <span className="grey_txt_bold">- Credit Checks. </span>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
        atque neque consequuntur dolores amet, molestias nam in nihil
        architecto, excepturi ducimus! Qui non odio laudantium
        accusantium provident est, ad perferendis consequuntur
        repellendus facere aperiam. Dignissimos vitae ea error earum,
        doloribus veritatis facere amet ipsa hic veniam repellendus
        nostrum inventore saepe!
      </p>
      <p className="grey_p_txt mb-3">
        <span className="grey_txt_bold">- Third Party Accounts. </span>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id
        atque neque consequuntur dolores amet, molestias nam in nihil
        architecto, excepturi ducimus! Qui non odio laudantium
        accusantium provident est, ad perferendis consequuntur
        repellendus facere aperiam. Dignissimos vitae ea error earum,
        doloribus veritatis facere amet ipsa hic veniam repellendus
        nostrum inventore saepe!
      </p>
    </li>
  </ul>



  <h3 className="policy_tle mb-3">3. HOW WE USE PERSONAL DATA</h3>
  <p className="grey_p_txt mb-3">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam,
    perferendis?
    <ul className="mt-3">
      <li className="mb-3">
        - Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Tenetur, quas praesentium. Ab animi numquam facere consectetur.
        Quam facilis, minus voluptates eum consectetur laboriosam aut,
        pariatur vero similique corporis suscipit. Unde.
      </li>
      <li className="mb-3">
        - Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
        quibusdam sapiente repellendus placeat fuga libero provident
        maiores eveniet inventore veritatis voluptatum, est error ipsam
        ab optio praesentium consequatur ducimus ullam dignissimos
        architecto temporibus debitis quidem ea. Eius voluptatum impedit
        placeat aspernatur doloribus provident omnis libero blanditiis,
        saepe eaque consequuntur ad!
      </li>
    </ul>
  </p>
  <h3 className="policy_tle mb-3">
    4. SHARING AND DISCLOSURE OF PERSONAL DATA
  </h3>
  <p className="grey_p_txt mb-3">
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quibusdam
    ullam quos perferendis nemo doloribus. Obcaecati, perspiciatis eos
    esse architecto distinctio aut, molestias inventore, asperiores
    fugiat consequatur aliquam laudantium qui nam:
  </p>
  <p className="grey_p_txt mb-3">
    <span className="grey_txt_bold">
      Vendors and Service Providers:
    </span>
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aut id
    maiores, in expedita illum pariatur error porro facere nemo minus
    sed officiis asperiores at itaque quod enim est accusantium minima
    excepturi! Eius, doloribus consequatur debitis ex nesciunt error
    quod atque distinctio modi? Quae, ab amet tenetur ut deserunt neque
    deleniti earum nihil asperiores distinctio odit corrupti nobis cum
    expedita nostrum error dolore nulla iure. Temporibus consequatur
    officiis obcaecati adipisci exercitationem sunt, a vero deserunt
    assumenda facere earum maiores sit repellat accusamus veniam numquam
    itaque inventore, rem reprehenderit! Unde, quia dolorum explicabo
    vero, ratione, delectus cupiditate commodi sunt eum aspernatur
    culpa.
  </p>
  <p className="grey_p_txt mb-3">
    <span className="grey_txt_bold">Legal Requirements:</span>
    If required to do so by law or in the good faith belief that such
    action is necessary to (i) comply with a legal obligation, including
    to meet national security or law enforcement requirements, (ii)
    protect and defend our rights or property, (iii) prevent fraud, (iv)
    act in urgent circumstances to protect the personal safety of users
    of the Services, or the public, or (v) protect against legal
    liability.
  </p>
  <p className="grey_p_txt mb-3">
    <span className="grey_txt_bold">Affiliates:</span>
    We may share Personal Data with our affiliates, meaning an entity
    that controls, is controlled by, or is under common control with
    Company. Our affiliates may use the Personal Data we share in a
    manner consistent with this Privacy Policy.
  </p>
  <h3 className="policy_tle mb-3">5. DATA RETENTION</h3>
  <p className="grey_p_txt mb-3">
    We keep Personal Data for as long as reasonably necessary for the
    purposes described in this Privacy Policy, while we have a business
    need to do so, or as required by law (e.g. for tax, legal,
    accounting or other purposes), whichever is the longer.
  </p>
  <h3 className="policy_tle mb-3">
    6. UPDATE / DELETE YOUR INFORMATION
  </h3>
  <p className="grey_p_txt mb-3">
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus,
    accusantium. Aut, quis saepe corporis maiores nulla eveniet,
    assumenda unde ut esse voluptates molestias fugiat dolorum omnis
    vitae explicabo eos quia doloremque minus iste facilis voluptatum
    suscipit deleniti placeat. Minus fugiat ipsa libero? Non
    voluptatibus quas mollitia animi nisi nihil voluptas!
  </p>
  <h3 className="policy_tle mb-3">
    7. CALIFORNIA PRIVACY RIGHTS DISCLOSURES
  </h3>
  <p className="grey_p_txt mb-3">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem odit
    nisi facere expedita ab facilis tempore ipsa dolor, numquam
    laudantium illum aliquid hic distinctio quae quod harum vitae vero
    quia, est possimus. Reprehenderit et at facilis dolor enim id esse
    ea, ut impedit sint rem quas quia tenetur, dolores animi. Soluta
    reprehenderit amet ipsa esse quae nulla. Delectus, repellat rem!
  </p>
  <h3 className="policy_tle mb-3">8. CHILDREN</h3>
  <p className="grey_p_txt mb-3">
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
    voluptatibus quibusdam et accusantium distinctio rerum ipsam eum nam
    itaque dolorem quia quidem mollitia placeat vero obcaecati nesciunt
    quae, doloribus nobis enim dicta velit quam quos! Atque debitis
    blanditiis rerum. Repellat nemo iste ipsum laborum quisquam numquam
    labore. Iure deserunt nobis et. Ipsum eligendi, omnis non corporis
    architecto laboriosam vero sint.
  </p>
</div>
</section> */}