import React, { useState } from 'react';
import {Breadcrumb,Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import eye from '../../../../Assets/images/eye.png'
import bag from '../../../../Assets/images/bag.png'

import color from '@material-ui/core/colors/amber';


const AccTravelForWork = () => {


    return(
        <div className='personalinfo nocenterhead'>
            <Header />
            <div className='mh-100vh'>
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Account</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/">Travel for work</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>Travel for work</h2>
                    </div>
                        <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                                <div className='cont'>
                                   <h4>Join JetToLet for Work</h4>
                                   <p className='text-muted'>Add your work email to get seamless expensing and exclusive offers on work trips.</p>
                                   
                                   <div className='mt-4 h-55'>
                                            <label className='text-muted mb-2 mt-1'><b>Work email address</b></label>
                                            <input type="email" className='form-control width-full' id="email"/>
                                           
                                            <button className='btn btn-theme mt-3 '>Add work email</button>

                                         </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={bag} className="mb-3"/>
                                    <h6>Which details can be edited?</h6>
                                    <p className='text-muted'>Details JetToLet uses to verify your identity can’t be changed. 
                                        Contact info and some personal details can be edited, but we may
                                         ask you to verify your identity the next time you book or create a listing.</p>
                                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccTravelForWork