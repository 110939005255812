import React, { Component, useEffect, useState } from "react";

import Scrollbars from "react-custom-scrollbars";
import { Form } from "react-bootstrap";

import img1 from "../../../Assets/images/where/img1.png";
import img2 from "../../../Assets/images/filter/bed.png";

import { API_URL } from "../../../config/env";
import { getUserDatas, getUserEmail } from "../../../Hooks/useUserHook";
const CheckList = (props) => {
  console.log("props.gal", props);
  console.log("props.amentiites", props.data.amenities);
  const [previewURL, setPreviewURL] = useState("");
  const [instantBooking, setInstantBooking] = useState(false);
  const [userdata, setUserDatas] = useState(getUserDatas());
  console.log("Instant Booking", getUserDatas(),userdata,instantBooking);
  useEffect(() => {
    const imageurl = URL.createObjectURL(props.data.photos[0]);
    // console.log("props.data inside checklist:",props.data,"imageurl",imageurl);
    setPreviewURL(imageurl);
    props.onGetCheckList(instantBooking);
  }, [instantBooking]);
  return (
    <div className=" col-lg-12 col-md-12 col-sm-12 col-12">
      <div className="rightcont placetype mb-5 checklist">
        <div
          className="row px-3 align-items-center height-100"
          data-aos="fade-up"
        >
          <Scrollbars
            style={{ height: "calc(100vh - 320px)" }}
            className="scroll"
          >
            <div className="bg-white pb-5 br-10">
              <img src={previewURL && previewURL} className="img-fluid checkout_listing_img" />
              <h3 className="place-title mt-3">{props.data.title}</h3>
              <hr />
              <div className="d-flex justify-content-between">
                <h6 className="fw-500">
                  {props.data.privacyType} in{" "}
                  {props.data.propertyTypeGroup.type} hosted by{" "}
                  {userdata?.firstName} {userdata?.lastName}
                </h6>
                <img src={img2} className="propf" />
              </div>
              <hr />
              <p className="f-14 break_tetx_new_room">
                {props?.data?.floorPlan.map((data) => {
                  if(data?.status == "Active") {
                    return (<>{data.type}:&nbsp;{data.count}
                      <span>&nbsp;|&nbsp;</span></>)
                  }
                }
                )}
              </p>
              <hr />
              <p className="f-14">{props.data.descriptionContent}</p>
              <hr />
              <div>
                <Form.Check
                  type="switch"
                  onChange={(e) => {
                    console.log("e.target.checked", e.target.checked);
                    setInstantBooking(e.target.checked);
                  }}
                  id="custom-switch"
                  className="ps-0 align-items-center"
                  label="Instant Booking"
                />
              </div>
              <hr />
              <h6 className="fw-700">Amenities</h6>
              <div className="">
                {props.data.amenities.map((data) => (
                  <>
                    <span className="badge bg-secondary me-1">{data.type}</span>
                  </>
                ))}
              </div>
              <hr />
              <h6 className="fw-700">Location</h6>
              <p>
                {props.data.address.street},{props.data.address.area},
                {props.data.address.city},{props.data.address.state},
                {props.data.address.postcode}.
              </p>
              <p className="f-12">
                We’ll only share your address with guests who are booked as
                outlined in our
                <a href="/privacy-policy" className="orsnge_y">
                  {" "}
                  <b> Privacy Policy</b>{" "}
                </a>{" "}
                .
              </p>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default CheckList;
