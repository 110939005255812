import React, { useEffect, useState } from "react";
import cardimg1 from "../../../../Assets/images/guest1.png";
import cardimg2 from "../../../../Assets/images/calendar1.png";
import cardimg3 from "../../../../Assets/images/book1.png";

import FirstGuestModal from "../Modals/FirstGuestModal";
import CustomizePrize from "../Modals/CustomizePrize";
import ConfirmGuestModal from "../Modals/ConfirmGuestModal";

import { useLocation } from "react-router-dom";

const HostCard = (props) => {
  const [firstguest, setFirstGuest] = useState(false);
  const [customprice, setCustomPrize] = useState(false);
  const [confirmguest, setConfirmGuest] = useState(false);
  const [cleanfee, setCleanFee] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    if (state == "rule") {
      setFirstGuest(true);
    } else if (state == "custom" || state == "clean") {
      setCustomPrize(true);
    } else if (state == "confirmguest") {
      setConfirmGuest(true);
    }
  }, [state]);

  const FinishSetup = [
    {
      name: "Get ready for your guests",
      img: cardimg1,
    },
    {
      name: "Calendar and pricing details",
      img: cardimg2,
    },
    {
      name: "Confirm how guests can book",
      img: cardimg3,
    },
  ];

  return (
    <>
      {firstguest && (
        <FirstGuestModal
          onDismiss={() => setFirstGuest(false)}
          img={cardimg1}
        />
      )}
      {customprice && (
        <CustomizePrize
          onDismiss={() => setCustomPrize(false)}
          img={cardimg2}
        />
      )}
      {console.log("confirmguest", confirmguest)}
      {confirmguest && (
        <ConfirmGuestModal
          onDismiss={() => setConfirmGuest(false)}
          img={cardimg3}
        />
      )}

      {FinishSetup.map((item, index) => {
        return (
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-4 mb-lg-0">
            <button className="custom-card" onClick={() => setFirstGuest(true)}>
              <div className="card-img-sec">
                <h6 className="fw-600 mb-0">{item.name}</h6>
                <img src={item.img} className="img-fluid mt-3" />
              </div>
            </button>
          </div>
        );
      })}
    </>
  );
};

export default HostCard;
