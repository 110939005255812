import React, { useEffect, useState } from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";

import { Link, useNavigate } from "react-router-dom";
import userIcon from "../../Assets/images/account-user-cion.png";
import basicplan from "../../Assets/images/basicplan.png";
import premiumplan from "../../Assets/images/premiumplan.png";
import ultraplan from "../../Assets/images/ultraplan.png";
import moment from "moment";
import HostSubscribeModal from "./HostSubscribeModal";
import { Gethostusersubscrptionhooks, getUserDatas, getusersubscriptiondetailshooks } from "../../Hooks/useUserHook";
import { API_URL } from "../../config/env";

const SubscriptionsList = (props) => {
  const [hostsubscribe, setHostsubscribe] = useState(false);
  const [subscrptions, setSubscriptions] = useState([]);
  const [singlesubscrpition, setSinglesubscription] = useState({});
  const [userdata, setUserData] = useState(getUserDatas());

  console.log("userdatasgal", userdata)
  const fetchdata = async () => {
    let data = {
      "hostsubscriberef": userdata?.hostsubscriberef,
      "guestsubscriberef": userdata?.guestsubscriberef
    }
    let res = await getusersubscriptiondetailshooks(data);
    console.log("res datasgal", res?.data?.data)
    let subscrdata = res?.data?.data;
    if (subscrdata?.length > 0) {
      setSubscriptions(subscrdata)
    }
  };


  useState(() => {
    fetchdata();
  }, [userdata]);

  return (
    <div id="hostaccount" className="nocenterhead account-details">
      <Header />

      {/* {hostsubscribe && (
        <HostSubscribeModal
          onDismiss={() => setHostsubscribe(false)}
          sub={singlesubscrpition}
          stripe={props?.stripe}
        />
      )}     */}
      <div className="container container_custom mt_new_aho_a">
        <div className="row">
          <div className="col-xl-12">
            <h3 className=" mt-lg-5 account">Subscriptions List</h3>
            <div className="plansrow_sub">
              {subscrptions && subscrptions?.length > 0 ?
                subscrptions?.map((data) => (

                  <div className="col-12 col-md-6 col-xl-4 mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
                    {/* <Link to="/account/personal-info" data = {userdata}> */}
                    {console.log('ddddddddddddddd',data,`${API_URL}subscriptionimages/${data?.subscriptiondetails?.image}`, data?.image, moment(new Date(Number(data?.subscriptiontransactiondetails?.expirydate))).format("DD-MM-YYYY"))}
                    <div className="acc-box accox_sm d-flex flex-column p-4 align-items-center justify-content-center">
                      <h2 className="subs_list_guest_host_txt">Host</h2>
                      <img
                        src={`${API_URL}subscriptionimages/${data?.subscriptiondetails?.image}`}
                        alt="user-icon"
                        className="user-icon-img "
                      />
                      <h6 className="pt-2 font_pers mt-3">{data?.subscriptiondetails?.name}</h6>
                      <p className="pt-2  text-center price_txt_new_sub">
                        ${data?.subscriptiondetails?.price}
                      </p>
                      <p className="pt-2 mb-0 text-center no-of_subs-days">
                        {/* {data?.days} days */}
                        Plan days : {data?.subscriptiondetails?.days} days
                      </p>
                      <p className=" pt-1 mb-0 text-center no-of_subs-days">
                        Start date : {`${moment(new Date(Number(data?.subscriptiontransactiondetails?.startdate))).format("DD-MM-YYYY")}`}
                      </p>
                      <p className=" pt-1 mb-0 text-center no-of_subs-days">
                        Expired date : {new Date() >= new Date(Number(data?.subscriptiontransactiondetails?.expirydate)) ?
                          "Expired Subscriptions" : `${moment(new Date(Number(data?.subscriptiontransactiondetails?.expirydate))).format("DD-MM-YYYY")}`}
                      </p>
                      {new Date() >= new Date(Number(data?.subscriptiontransactiondetails?.expirydate)) && <p className=" pt-1 mb-0 text-center no-of_subs-days"> Expired !!!</p>}

                    </div>
                  </div>
                )) : <>
                  <div className="text-center">
                    {parseFloat(userdata?.hostsubscribedaycount) <= 0 ? <h4 className="need_to_subs"> Need Host Subscription </h4> : <></>}
                    {(parseFloat(userdata?.guestsubscribedaycount) <= 0 && parseFloat(userdata?.hostsubscribedaycount) <= 0) && <p className="need-to-deactive mb-2">
                      Need to subscribe?
                    </p>}
                    <div className={parseFloat(userdata?.guestsubscribedaycount) <= 0 || parseFloat(userdata?.hostsubscribedaycount) <= 0 ? "subscribe_block" : "subscribe_block"}>

                      {/* <div className={parseFloat(userdata?.guestsubscribedaycount) <=0 || parseFloat(userdata?.hostsubscribedaycount) <=0?"subscribe_flex":"subscribe_block"}> */}
                      {parseFloat(userdata?.hostsubscribedaycount) <= 0 ? <p className="take-care-of mb-3 mt-3">
                        <Link to={"/hostsubscribe"} className="btn btn_theme_black">
                          Subscribe for Host
                        </Link>
                      </p> :
                        // <p className="take-care-of mb-3 mt-3">
                        //   Host subscribe expired in {userdata?.hostsubscribedaycount} days
                        //  </p>
                        <></>
                      }
                      {parseFloat(userdata?.guestsubscribedaycount) <= 0 || parseFloat(userdata?.hostsubscribedaycount) <= 0 &&
                        <p className="take-care-of mb-3 mb-sm-0 mt-3">
                          or
                        </p>
                      }
                      {parseFloat(userdata?.guestsubscribedaycount) <= 0 ? <p className="take-care-of">
                        <Link to={"/guestsubscribe"} className="btn btn_theme_black">
                          Subscribe for Guest
                        </Link>
                      </p> : <p className="take-care-of mb-3 mt-3">
                        {console.log('userdata?.guestsubscribedaycount', userdata, userdata?.guestsubscribedaycount)}
                        Guest subscribe expired in {userdata?.guestsubscribedaycount} days

                      </p>}
                    </div>
                  </div>
                </>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SubscriptionsList;
