import React, { useEffect, useState } from "react";

import HostCard from "./Cards/HostCard";
import HostHeader from "./HostHeader";
import HostUpdateCard from "./Cards/HostUpdateCard";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import Footer from "../../Common/Footer";
import { Link } from "react-router-dom";
import { checkExperienceBookStatus } from "../../../Hooks/useUserExperienceHook";
import YourReservationTab from "./YourReservationTab";
import {
  getArrivingSoon,
  getPendingBookings,
  getCheckoutToday,
  getUpcomingBookings,
  getcancelletbooking,
} from "../../../Hooks/useUserHostingHook";
import { getUserDatas, getUserEmail } from "../../../Hooks/useUserHook";
import reservation from "../../../Assets/images/reservation.png";
import { toast } from "react-hot-toast";
const StartHosting = () => {
  const [arrivingSoon, setArrivingSoon] = useState([]);
  const [pendingBookings, setPendingBookings] = useState([]);
  const [checkOutToday, setCheckOutToday] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [email, setEmail] = useState("");
  const [cancelbooking, setCancelbooking] = useState([]);

  const getData = (hostEmail) => {
    const data = getUserEmail();
    setEmail(data);

    getArrivingSoon(hostEmail).then((data) => {
      console.log("getArrivingSoon", data);
      setArrivingSoon(data);
    });
    getPendingBookings(hostEmail).then((data) => {
      console.log("getPendingBookings", data);
      setPendingBookings(data);
    });
    getCheckoutToday(hostEmail).then((data) => {
      console.log("getCheckoutToday", data);
      setCheckOutToday(data);
    });
    getUpcomingBookings(hostEmail).then((data) => {
      console.log("getUpcomingBookings", data);
      setUpcomingBookings(data);
    });
    getcancelletbooking(hostEmail).then((data) => {
      setCancelbooking(data);
    });
  };

  const refreshPage = () => {
    const mail = window.location.pathname.split("/")[2];
    getData(mail);
    checkExperienceBookStatus(mail);
  };

  // const checksubscription = () =>{
  //     let user = getUserDatas();
  //     if(parseFloat(user?.hostsubscribedaycount) <= 0){
  //         toast.error("Need Host subscription for use all host option!")
  //     }
  // }

  useEffect(() => {
    const mail = window.location.pathname.split("/")[2];
    console.log("maildata", mail);
    getData(mail);
  }, []);

  const Notification = [
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
  ];
  return (
    <div>
      <HostHeader email={email} />
      <div className="themebg dashboard-today-sec">
        <div className="container py-5">
          <div className="head-sec">
            <h2 className="today heading-txt">Your Dashboard</h2>
            <p className="descrip">
              You’re on your way to being an incredible host! Here’s how to
              start.
            </p>
          </div>
          <div className="todaysrequest mt-4">
            <h4 className="heading-txt fw-700">Notifications</h4>
            <div className="row mt-3">
              {Notification.map((item, index) => {
                return (
                  <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mb-3">
                    <Link
                      className="text-decoration-none text-black"
                      to="/hosting/inbox"
                    >
                      <div className="notification-card">
                        <p className="text-left mb-0">New message from:</p>

                        <h4 className="name fw-600 my-2 d-flex align-items-center justify-content-between">
                          John Doe
                          <span className="fa fa-info-circle f-15" />
                        </h4>
                        <p className="desc mb-0 lh-19 f-15">
                          This is the demo message that John Doe wrote{" "}
                        </p>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="cardsec mt-5 finish-setup">
            <h4 className="heading-txt fw-700">Finish Setup</h4>
            <div className="row mt-4">
              <HostCard class="large" />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="checking-out">
          <h4 className="mb-0 fw-700">Checking Out</h4>

          <div className="common-checking-card mt-4">
            {checkOutToday && checkOutToday.length !== 0 ? (
              <YourReservationTab
                onRefresh={refreshPage}
                email={email}
                isPending={false}
                datas={checkOutToday}
              />
            ) : (
              <div className="row no-stat d-flex align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                  <img
                    src={reservation}
                    alt="reservation-img"
                    className="reservation img-fluid"
                  />
                  <p className="mb-0 mt-3 fw-500 text-center">
                    You don’t have any guests checking out today or tomorrow.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="checking-out mt-3 mt-lg-4">
          <h4 className="mb-0 fw-700">Currently Hosting</h4>

          <div className="common-checking-card mt-4">
            {pendingBookings && pendingBookings.length !== 0 ? (
              <YourReservationTab
                onRefresh={refreshPage}
                email={email}
                isPending={true}
                datas={pendingBookings}
              />
            ) : (
              <div className="row no-stat d-flex align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                  <img
                    src={reservation}
                    alt="reservation-img"
                    className="reservation img-fluid"
                  />
                  <p className="mt-4 mb-0 text-center">
                    You don’t have any hosting bookings.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="checking-out mt-3 mt-lg-4">
          <h4 className="mb-0 fw-700">Arriving Soon</h4>

          <div className="common-checking-card mt-4">
            {arrivingSoon && arrivingSoon.length !== 0 ? (
              <YourReservationTab
                onRefresh={refreshPage}
                email={email}
                isPending={false}
                datas={arrivingSoon}
              />
            ) : (
              <div className="row no-stat d-flex align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                  <img
                    src={reservation}
                    alt="reservation-img"
                    className="reservation img-fluid"
                  />
                  <p className="mt-4 mb-0 text-center">
                    You don’t have any arriving bookings.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="checking-out mt-3 mt-lg-4">
          <h4 className="mb-0 fw-700">Upcoming</h4>

          <div className="common-checking-card mt-4">
            {upcomingBookings && upcomingBookings.length !== 0 ? (
              <YourReservationTab
                onRefresh={refreshPage}
                email={email}
                isPending={false}
                datas={upcomingBookings}
              />
            ) : (
              <div className="row no-stat d-flex align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                  <img
                    src={reservation}
                    alt="reservation-img"
                    className="reservation img-fluid"
                  />
                  <p className="mt-4 mb-0 text-center">
                    You don’t have any upcoming bookings.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="checking-out mt-3 mt-lg-4">
          <h4 className="mb-0 fw-700">Cancelled</h4>

          <div className="common-checking-card mt-4">
            {cancelbooking && cancelbooking.length !== 0 ? (
              <YourReservationTab
                onRefresh={refreshPage}
                email={email}
                isPending={false}
                datas={cancelbooking}
              />
            ) : (
              <div className="row no-stat d-flex align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                  <img
                    src={reservation}
                    alt="reservation-img"
                    className="reservation img-fluid"
                  />
                  <p className="mt-4 mb-0 text-center">
                    You don’t have any cancelled bookings.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <div className="container">
        <div className="reserve mb-5">
          <div className="reseve-tabs mb-5">
            <Tab.Container id="left-tabs-example" defaultActiveKey="checkout">
              <Row className="d-flex align-items-center justify-content-between flex-column">
                <Col className="mx-auto bg-white mt-4 mb-3">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      <Nav.Link eventKey="checkout">
                        Checking out <span>({checkOutToday.length})</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="currenthosting">
                        Currently Hosting{" "}
                        <span>({pendingBookings.length})</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="arriving">
                        Arriving soon <span>({arrivingSoon.length})</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="upcoming">
                        Upcoming <span>({upcomingBookings.length})</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="cancelled">
                        Cancelled <span>({cancelbooking.length})</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col className="mx-auto mt-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="checkout">
                      {checkOutToday && checkOutToday.length !== 0 ? (
                        <YourReservationTab
                          onRefresh={refreshPage}
                          email={email}
                          isPending={false}
                          datas={checkOutToday}
                        />
                      ) : (
                        <div className="no-stat">
                          <div className="no-stat-cont">
                        
                            <img
                              src={reservation}
                              alt="reservation-img"
                              className="reservation"
                            />
                            <p className="my-4">
                              You don’t have any guests checking out today or
                              tomorrow.
                            </p>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="currenthosting">
                      {pendingBookings && pendingBookings.length !== 0 ? (
                        <YourReservationTab
                          onRefresh={refreshPage}
                          email={email}
                          isPending={true}
                          datas={pendingBookings}
                        />
                      ) : (
                        <div className="no-stat">
                          <div className="no-stat-cont">
                            
                            <img
                              src={reservation}
                              alt="reservation-img"
                              className="reservation"
                            />
                            <p className="my-4">
                              You don’t have any hosting bookings.
                            </p>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="arriving">
                      {arrivingSoon && arrivingSoon.length !== 0 ? (
                        <YourReservationTab
                          onRefresh={refreshPage}
                          email={email}
                          isPending={false}
                          datas={arrivingSoon}
                        />
                      ) : (
                        <div className="no-stat">
                          <div className="no-stat-cont">
                           
                            <img
                              src={reservation}
                              alt="reservation-img"
                              className="reservation"
                            />
                            <p className="my-4">
                              You don’t have any arriving bookings.
                            </p>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="upcoming">
                      {upcomingBookings && upcomingBookings.length !== 0 ? (
                        <YourReservationTab
                          onRefresh={refreshPage}
                          email={email}
                          isPending={false}
                          datas={upcomingBookings}
                        />
                      ) : (
                        <div className="no-stat">
                          <div className="no-stat-cont">
                           
                            <img
                              src={reservation}
                              alt="reservation-img"
                              className="reservation"
                            />
                            <p className="my-4">
                              You don’t have any upcoming bookings.
                            </p>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="cancelled">
                      {cancelbooking && cancelbooking.length !== 0 ? (
                        <YourReservationTab
                          onRefresh={refreshPage}
                          email={email}
                          isPending={false}
                          datas={cancelbooking}
                        />
                      ) : (
                        <div className="no-stat">
                          <div className="no-stat-cont">
                        
                            <img
                              src={reservation}
                              alt="reservation-img"
                              className="reservation"
                            />
                            <p className="my-4">
                              You don’t have any cancelled bookings.
                            </p>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div> */}

      {/* <div className='host-tips'>
            <div className='container'>
                <h4 className='mb-4'>Hosting tips & Updates</h4>
                    <div className='row'>
                        <HostUpdateCard class="small"/>
                    </div>
            </div>
        </div> */}

      <Footer />
    </div>
  );
};

export default StartHosting;
