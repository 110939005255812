import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../../config/env";


const EditSpace = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState("");
    const setData = (val, index) => {
      {
        console.log("val,index", props,val, index);
      }
      setSelectedIndex(index);
      setSelectedValue(val);
      localStorage.setItem("EditspaceIndex", index);
      props.onGetSpace(val);
    };
  
    useEffect(() => {
        if (props?.roomdata) {
            let checkarrIndex = [
                {
                    name : "An Entire Place",
                    id : 1
                },
                {
                    name : "A Private Room",
                    id : 2
                },
                {
                    name : "A Shared Room",
                    id : 3
                },
                {
                    name : "Hotels",
                    id : 4
                },
                {
                    name : "Classic Rooms",
                    id : 5
                }
            ];


        let setdata = checkarrIndex?.filter(el => (el.name).toLowerCase() == (props?.roomdata?.privacyType).toLowerCase());
        console.log('consol setdata', setdata[0]);
        let index = Number(setdata[0]?.id) - 1;

        let setIndex = localStorage.getItem("EditspaceIndex") != null ? localStorage.getItem("EditspaceIndex") : props?.roomdata?.privacyType ? index : selectedIndex;
        localStorage.getItem("EditprivacyType") != null ? props.onGetSpace(JSON.parse(localStorage.getItem("EditprivacyType"))) :
        props?.roomdata?.privacyType ? props.onGetSpace(props?.roomdata?.privacyType) : localStorage.removeItem("EditspaceIndex");
        setSelectedIndex(setIndex)

        }


    }, [localStorage.getItem("placeTypeIndex"), props])
  
    return (
      <div className="col-md-12">
        <div className="placetype rightcont mb-5 describeplace">
          <Scrollbars style={{ height:"calc(100vh - 320px)" }} className="scroll">
            <div
              className="row  px-3 py-2 align-items-center mb-5"
              data-aos="fade-up"
            >
              <ul>
                {props.data &&
                  props.data.content.map((val, index) => (
                    // val?.s
                    val?.status != "Inactive" && 
                    <li>
                      <button
                        onClick={() => {
                          setData(val.type, index);
                          props?.Filter("privacyType")
                        }}
                        className={
                          index == selectedIndex
                            ? "placecards active p-4 custom_img_icon"
                            : "placecards p-4 custom_img_icon"
                        }
                      >
                        {/* Icon */}
                      <img src={`${API_URL}${props?.data?.path}/${val?.image}`} alt='icon' className="img-fluid" />
  
                        <h6 className="mb-0">{val.type}</h6>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  };
  
  export default EditSpace;