import React, { Component, useState, useEffect } from "react";

import { propTypes } from "react-bootstrap/esm/Image";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../../config/env";

const EditCancellationPolicy = (props) => {
  console.log("cancal props", props);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");

  const setData = (val, index) => {
    setSelectedIndex(index);
    setSelectedValue(val);
    localStorage.setItem("EditcancellationPolicyIndex", index);
    props.onGetCancellationPolicy(val);
  };

  useEffect(() => {
    console.log('editcvanasdfasdf', props?.roomdata, props?.roomdata?.legal, props?.data?.content)
    if (props?.roomdata) {
      let index = Number(props?.roomdata?.legal[0]?.id) - 1;
      let setIndex = localStorage.getItem("EditcancellationPolicyIndex") != null ? localStorage.getItem("EditcancellationPolicyIndex") :
        props?.roomdata?.legal ? index : selectedIndex;
      console.log('setIndex data', setIndex)
      localStorage.getItem("EditcancellationPolicy") != null ? props.onGetCancellationPolicy(JSON.parse(localStorage.getItem("EditcancellationPolicy"))) :
        props?.roomdata?.legal ? props.onGetCancellationPolicy(props?.roomdata?.legal[0]) : localStorage.removeItem("EditcancellationPolicyIndex")
      setSelectedIndex(setIndex)
    }
  }, [localStorage.getItem("EditcancellationPolicyIndex"), props])

  return (
    <div className="col-md-12">
      <div className="placetype rightcont mb-5 describeplace">
        <Scrollbars
          style={{ height: "calc(100vh - 320px)" }}
          className="scroll"
        >
          <div
            className="row  px-3 align-items-center height-100 mb-5 py-2"
            data-aos="fade-up"
          >
            <div className="row">
              {props.data &&
                props.data.content.map((val, index) => {
                  return (
                    <>
                      {val?.status != "Inactive" && (
                        <div className="col-md-4">
                          <button
                            onClick={() => {
                              setData(val, index);
                              props?.Filter("legal")
                            }}
                            className={
                              index == selectedIndex
                                ? "placecards active p-4"
                                : "placecards p-4"
                            }
                          >
                            <div className="d-flex flex-column align-items-center justify-content-center py-4">
                              <img
                                src={
                                  API_URL + props.data.path + "/" + val.image
                                }
                              />
                              <h6 className="mb-0">{val.type}</h6>
                            </div>
                          </button>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default EditCancellationPolicy;
