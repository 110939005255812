import React, { useEffect, useState } from "react";
import { Row, Col, Tab, Nav, Button } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";

import { useLocation } from "react-router-dom";
import { toastOptions } from "../../../config/env";
import { getUserDatas } from "../../../Hooks/useUserHook";
import { hostRoomApproval } from "../../../Hooks/useUserHostingHook";
import Modal from "react-bootstrap/Modal";

const YourReservationTab = (props) => {
  const [show, setShow] = useState(false);
  const [toggleVal, setToggleVal] = useState(false);
  const [toggleArr, setToggArr] = useState([]);
  const [splitreservedata, setsplitReserveData] = useState({});
  console.log("toggArrdata", toggleArr);
  const handleClose = () => {
    setShow(false);
    setToggleVal(false);
    setToggArr([]);
  };
  const handleShow = () => {
    setShow(true);
    setToggleVal(true);
  };

  const approve = async (data, index, isrejected) => {
    let user = getUserDatas();
    const value = {
      email: props.email,
      isVerified: data,
      bookingId: props.datas[index]._id,
      roomId: props.datas[index].roomId,
      isRejected: isrejected,
      id: user?._id,
    };
    console.log(
      "value from your reservation tab",
      value,
      "props.datas",
      props.datas
    );
    await hostRoomApproval(value).then((data) => {
      console.log("data in approve", data);
      if (data?.type == "error") {
        toast.error(data?.msg);
      } else {
        toast.success(data, toastOptions);
        props.onRefresh();
      }
    });
  };

  const handleToggle = (e, ind) => {
    // console.log("toggle value", e.target.checked);
    if (e.target.checked) {
      handleShow();
      setToggArr([{ ind: ind, toggleval: true }]);
      setToggleVal(true);
    }
  };

  return (
    <>
      <div className="no-stat-new">
        <div className="row withstat">
          {props?.datas?.map((val, index) => (
            <>
              <div className="cols col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-lg-6 mb-3">
                <div className="room-detail shadow-box">
                  <div>
                    <div className="">
                      <h5 className="mb-0 fw-600">
                        {val?.roomTitle} at {val?.accommodationAddress?.city}
                      </h5>
                      <p className="mb-0 f-16 fw-500">Venice, CA</p>
                    </div>
                    <div className="graybox br-0 mt-3 py-3 mobi_fle_ger">
                      <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                          <p className="heading">Booked by</p>
                          <p className="text-muted">{val.email}</p>
                        </h6>
                      </div>
                      <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                          <p className="heading fw-600">Room ID</p>
                          <p className="text-muted">{val.roomId}</p>
                        </h6>
                      </div>

                      <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                          <p className="heading fw-600">Booking ID</p>{" "}
                          <p className="text-muted">{val._id}</p>
                        </h6>
                      </div>
                      {val?.splitpayment && (
                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14 d-flex align-items-center justify-content-between w-100">
                            <div>
                              <p className="heading fw-600">SplitReserve</p>{" "}
                              <p className="text-muted">{val._id}</p>
                            </div>
                            <div className="custom_toggle_switch">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onChange={(e) => {
                                    handleToggle(e, index);
                                    setsplitReserveData(val);
                                  }}
                                  checked={
                                    toggleArr?.length > 0
                                      ? toggleArr[0].ind == index
                                        ? true
                                        : false
                                      : false
                                  }
                                />
                                <span className="slider round" />
                              </label>
                            </div>
                          </h6>
                        </div>
                      )}

                      <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                          <p className="heading fw-600">Check In</p>{" "}
                          <p className="text-muted">
                            {new Date(val.checkInDate).toLocaleDateString()}
                          </p>
                        </h6>
                      </div>

                      <div className="d-flex jc-between">
                        <h6 className="mb-3 f-14">
                          <p className="heading fw-600">CheckOut</p>{" "}
                          <p className="text-muted">
                            {new Date(val.checkOutDate).toLocaleDateString()}
                          </p>
                        </h6>
                      </div>

                      {/* <div className="d-flex jc-between">
                      <h6 className="mb-3 f-14">
                        <p className="heading fw-600">Amount</p>{" "}
                        <p className="text-muted">
                          {Number(val?.totalAmount) -
                            Number(val?.serviceFeeAmount)}
                        </p>
                      </h6>
                    </div> */}

                      {/* <div className="d-flex jc-between">
                      <h6 className="mb-3 f-14">
                        <p className="heading fw-600">ServiceFee</p>{" "}
                        <p className="text-muted">{val?.serviceFeeAmount}</p>
                      </h6>
                    </div> */}
                    </div>
                  </div>
                  <div>
                    <h6 className="mb-3 f-14 mt-3 d-flex jc-between">
                      <span className="lite-gray">{val.nights} Night(s)</span>
                    </h6>
                    <hr className="heading" />

                    <h6 className="mb-0 f-14 mt-3 d-flex jc-between">
                      <span className="heading">Amount:</span>{" "}
                      <span className="amount-dly">${val.totalAmount}</span>
                    </h6>
                  </div>
                  {props.isPending ? (
                    <div className="d-flex mt-3">
                      <button
                        onClick={() => {
                          approve(true, index, false);
                        }}
                        type="button"
                        class="btn btn-theme  me-2"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => {
                          approve(false, index, true);
                        }}
                        type="button"
                        class="btn btn-theme "
                      >
                        Decline
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="split_modal_popup"
      >
        <Modal.Header closeButton>
          <Modal.Title>SplitReserve</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {console.log("props.datasprops.datas", splitreservedata)}
          <div className="row withstat">
            {splitreservedata &&
              splitreservedata?.splitpaymentDetails?.length > 0 &&
              splitreservedata?.splitpaymentDetails?.map((val, index) => (
                <>
                  {console.log("splitpayvalsgal", val)}
                  <div className="col-xl-12 col-12 mb-3">
                    <div className="shadowbox p-4 br-5">
                      <div className="d-flex align-items-center">
                        {/* <img src={prof} className="prof" /> */}
                        <h6 className="mb-3">
                          <b>
                            {splitreservedata?.roomTitle} at{" "}
                            {splitreservedata?.accommodationAddress?.city}
                          </b>
                        </h6>
                      </div>
                      <div className="graybox br-0 mt-3 py-3 mobi_fle_ger">
                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">Booked by</p>
                            <p className="text-muted">{val.email}</p>
                          </h6>
                        </div>
                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">Room ID</p>
                            <p className="text-muted">
                              {splitreservedata.roomId}
                            </p>
                          </h6>
                        </div>

                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">Booking ID</p>{" "}
                            <p className="text-muted">{splitreservedata._id}</p>
                          </h6>
                        </div>
                        {val?.splitpayment && (
                          <div className="d-flex jc-between">
                            <h6 className="mb-3 f-14 d-flex align-items-center justify-content-between w-100">
                              <div>
                                <p className="themeclr">SplitReserve</p>{" "}
                                <p className="text-muted">{val._id}</p>
                              </div>
                            </h6>
                          </div>
                        )}

                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">Check In</p>{" "}
                            <p className="text-muted">
                              {new Date(val.startDate).toLocaleDateString()}
                            </p>
                          </h6>
                        </div>

                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">CheckOut</p>{" "}
                            <p className="text-muted">
                              {new Date(val.endDate).toLocaleDateString()}
                            </p>
                          </h6>
                        </div>

                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">Amount</p>{" "}
                            <p className="text-muted">{val?.splitamount}</p>
                          </h6>
                        </div>

                        <div className="d-flex jc-between">
                          <h6 className="mb-3 f-14">
                            <p className="themeclr">ServiceFee</p>{" "}
                            <p className="text-muted">{val?.serviceFee}</p>
                          </h6>
                        </div>
                      </div>
                      <h6 className="mb-3 f-14 mt-3 d-flex jc-between">
                        <span className="text-muted">
                          {val.nights} Night(s)
                        </span>
                      </h6>
                      <hr className="themeclr" />
                      <h6 className="mb-0 f-14 mt-3 d-flex jc-between">
                        <span className="themeclr">TotalAmount:</span>{" "}
                        <span className="success-box suc_box_blue">
                          ${val.totalamount}
                        </span>
                      </h6>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-theme"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Toaster />
    </>
  );
};

export default YourReservationTab;
