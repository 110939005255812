import React, { useEffect, useState, useRef } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUserDatas, getUserEmail, getusersdatahooks } from "../../Hooks/useUserHook";
import userIcon from "../../Assets/images/account-user-cion.png";
const HostAccount = () => {
  const [userDatas, setUserDatas] = useState({});
  const [isHost, setIshost] = useState(true);

  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    async function fetchdata() {
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      setUserDatas(userdata);
        console.log("loc_location", userdata);
    }
    fetchdata()
  }, [location]);


  // const [height, setHeight] = useState(window.innerHeight);
  // const updateDimensions = () => {
  //   setHeight(window.innerHeight);
  // }

  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   return () => window.removeEventListener("resize", updateDimensions);
  // }, []);


  const textRef = useRef()
  useEffect(() => {
    if (textRef.current) {
      window.scrollTo(0, textRef.current.offsetTop);
    }
  }, []);





  // console.log("height val", height)
  return (
    <div id="hostaccount" className="nocenterhead account-details">
      <Header />
      <div className="container container_custom mt_new_aho_a">
        <div className="row">
          <div className="col-xl-12">
            <h3 className=" mt-lg-5 account" ref={textRef}> Account</h3>
            <p className="account-name">
              <b>
                {userDatas && userDatas.firstName}&nbsp;
                {userDatas && userDatas.lastName}
              </b>
              , <span className="email">{userDatas && userDatas.email}</span> ·{" "}
              <b>
                <a
                  href={window.location.origin + "/account/profile"}
                  className="text-dark"
                >
                  Go to profile
                </a>
              </b>
            </p>
            <div className="mt-lg-5 mt-4 d-flex justify-content-center align-items-center">
              {/* <Link to="/account/personal-info" data = {userDatas}> */}
              <div className="acc-box d-flex flex-column p-4 align-items-center justify-content-center">
                {/* <span className="fa fa-user-o" /> */}
                <img
                  src={userIcon}
                  alt="user-icon"
                  className="user-icon-img "
                />
                <h6 className="pt-2 font_pers">Personal info</h6>
                <p className="pt-2 text-center">
                  Provide personal details and how we can reach you
                </p>
                <button
                  className="mt-3 mt-xl-5 mt-lg-5"
                  onClick={() => {
                    navigate("/account/personal-info", { state: userDatas });
                  }}
                >
                  Edit
                </button>
                <div className="mt-3">
                  <Link to="/account/addbank" className="text-dark">Add Bank Details</Link>
                </div>
              </div>

              {/* </Link> */}

              {/* <div className='col-lg-4  col-md-6'>
                                <Link to="/account/login-security">
                                <div className='acc-box'>
                                    <span className='fa fa-shield'></span>
                                    <h6>Login & security</h6>
                                    <p>Update your password and secure your account</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/payment">
                                <div className='acc-box'>
                                    <span className='fa fa-credit-card'></span>
                                    <h6>Payments & payouts</h6>
                                    <p>Review payments, payouts, coupons, gift cards, and taxes</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/notification">
                                <div className='acc-box'>
                                    <span className='fa fa-bullhorn'></span>
                                    <h6>Notifications</h6>
                                    <p>Choose notification preferences and how you want to be contacted</p>
                                </div>
                                </Link>
                            </div>

                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/privacyandsharing">
                                <div className='acc-box'>
                                    <span className='fa fa-eye'></span>
                                    <h6>Privacy & sharing</h6>
                                    <p>Control connected apps, what you share, and who sees it</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/globalperform">
                                <div className='acc-box'>
                                    <span className='fa fa-globe'></span>
                                    <h6>Global preferences</h6>
                                    <p>Set your default language, currency, and timezone</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/travelforwork">
                                <div className='acc-box'>
                                    <span className='fa fa-desktop'></span>
                                    <h6>Travel for work</h6>
                                    <p>Add a work email for business trip benefits</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/hostingtools">
                                <div className='acc-box'>
                                    <span className='fa fa-bar-chart'></span>
                                    <h6>Professional hosting tools</h6>
                                    <p>Get professional tools if you manage several properties on Airbnb</p>
                                </div>
                                </Link>
                            </div>
                            <div className='col-lg-4  col-md-6'>
                                <Link to="/account/guestrefferal">
                                <div className='acc-box'>
                                    <span className='fa fa-gift'></span>
                                    <h6>Referral credit & coupons</h6>
                                    <p>Your referral credits and coupon balance is $0. Learn more.</p>
                                </div>
                                </Link>
                            </div> */}
            </div>
            {/* <div className="shadowbox py-4"> */}
            <div className="my-4 text-center">
              {parseFloat(userDatas?.hostsubscribedaycount) <= 0 ? <h4 className="need_to_subs"> Need Host Subscription </h4> : <></>}
             {(parseFloat(userDatas?.guestsubscribedaycount) <= 0 && parseFloat(userDatas?.hostsubscribedaycount) <= 0) && <p className="need-to-deactive mb-2">
                Need to subscribe?
              </p>}
              <div className={parseFloat(userDatas?.guestsubscribedaycount) <= 0 || parseFloat(userDatas?.hostsubscribedaycount) <= 0 ? "subscribe_block" : "subscribe_block"}>

                {/* <div className={parseFloat(userDatas?.guestsubscribedaycount) <=0 || parseFloat(userDatas?.hostsubscribedaycount) <=0?"subscribe_flex":"subscribe_block"}> */}
                {parseFloat(userDatas?.hostsubscribedaycount) <= 0 ? <p className="take-care-of mb-3 mt-3">
                  <Link to={"/hostsubscribe"} className="btn btn_theme_black">
                    Subscribe for Host
                  </Link>
                </p> :
                  // <p className="take-care-of mb-3 mt-3">
                  //   Host subscribe expired in {userDatas?.hostsubscribedaycount} days
                  //  </p>
                  <></>
                }
                {parseFloat(userDatas?.guestsubscribedaycount) <= 0 || parseFloat(userDatas?.hostsubscribedaycount) <= 0 &&
                  <p className="take-care-of mb-3 mb-sm-0 mt-3">
                    or
                  </p>
                }
                {parseFloat(userDatas?.guestsubscribedaycount) <= 0 ? <p className="take-care-of">
                  <Link to={"/guestsubscribe"} className="btn btn_theme_black">
                    Subscribe for Guest
                  </Link>
                </p> : <p className="take-care-of mb-3 mt-3">
                  {console.log('userDatas?.guestsubscribedaycount', userDatas, userDatas?.guestsubscribedaycount)}
                  Guest subscribe expired in {userDatas?.guestsubscribedaycount} days

                </p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Footer />
    </div>
  );
};

export default HostAccount;
