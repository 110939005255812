import React ,{useEffect, useState} from 'react';
import {Accordion,Button} from 'react-bootstrap'
import Footer from '../../../../Common/Footer';
import HostHeader from '../../HostHeader';
import img17 from '../../../../../Assets/images/where/img17.png'
import img14 from '../../../../../Assets/images/where/img14.png'
import img16 from '../../../../../Assets/images/where/img16.png'
import img18 from '../../../../../Assets/images/where/img18.png'

import MyMapComponent from '../../../../Landing/MyMapComponent';
import ScenicModal from './Modals/ScenicModal';
import { Link } from 'react-router-dom';

const ManageSpace = () =>{
    const[scenic , setScenic] = useState(false)
    // useEffect(() =>{
    //     console.log(document.getElementById("location").scrollTop)
    // });
    // window.onscroll(
    //    document.getElementById("location").scrollTop = "200")? alert("dfgj") : alert("dfgjg");
    // )
    

    const hideBlock = (e) =>{
    //    e.target.parentNode.nextElementSibling.classList.add("d-block")
    //    e.target.parentNode.nextElementSibling.classList.remove("d-none")
    //    e.target.parentNode.classList.add("d-none")
    }
    const showBlock = (e) =>{
    //     e.target.parentNode.parentNode.classList.add("d-none")
    //    e.target.parentNode.parentNode.previousElementSibling.classList.remove("d-none")

    }

    return(
        <div className='managespace'>
            {scenic && <ScenicModal onDismiss={() => setScenic(false)} />}
            <HostHeader />
            <div className='container container_theme'>
                <div className='row mt-5'>
                    <div className='col-md-3 stikyavail'>
                    <Accordion defaultActiveKey="0" className='mngspace'>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Listing Details</Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                <li><a className='text-dark nounder active' href="#photos">Photos</a></li>
                                <li><a className='text-dark nounder' href="#listing-basic">Listing basics</a></li>
                                <li><a className='text-dark nounder' href="#amenities">Amenities</a></li>
                                <li><a className='text-dark nounder' href="#location">Location</a></li>
                                <li><a className='text-dark nounder' href="#property">Property and Rooms</a></li>
                                <li><a className='text-dark nounder' href="#accessibility">Accessibility</a></li>
                                <li><a className='text-dark nounder' href="#guestsafety">Guest safety</a></li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Pricing and availability</Accordion.Header>
                            <Accordion.Body>
                            <ul>
                                <li><a className='text-dark nounder active' href="#photos">Pricing</a></li>
                                <li><a className='text-dark nounder' href="#listings">Discounts</a></li>
                                <li><a className='text-dark nounder' href="#location">Additional charges</a></li>
                                <li><a className='text-dark nounder' href="#property">Taxes</a></li>
                                <li><a className='text-dark nounder' href="#accessibility">Trip length</a></li>
                                <li><a className='text-dark nounder' href="#guestsafety">Calendar sync</a></li>
                                <li><a className='text-dark nounder' href="#guestsafety">Sharing settings</a></li>
                            </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='col-md-8'>
                        <div className='jhfghg'>
                            
                        </div>
                        <div id="photos" >
                            <div className='d-flex jc-between mb-3'>
                                <p>Photos</p>
                                <Link to="/hosting/managespace/photos"  className="text-dark">Edit  <span className='fa fa-chevron-right ms-2 f-14'></span></Link>
                              
                            </div>
                            <div className='photos'>
                                    <img src={img17} />
                                    <img src={img14} />
                                    <img src={img16} />
                                    <img src={img18} />
                                    <img src={img14} />
                                    <img src={img17} />
                                    <Button variant='link' className='text-dark'>5 Photos</Button>
                            </div>

                        </div>
                        <hr className='themehr my-4'/>
                        <div className='basiclists' id="listing-basic">
                           <h5>Listing basics</h5>
                           <div className='blocks'>  
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Listing Titles</h6>
                                        <p className='text-muted f-14'>just for testing</p>
                                    </div>
                                    <Button className='text-dark' variant="link" id="viji" 
                                    onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none my-4'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Listing title</b></p>
                                            <p className='f-12'>Your listing title should highlight what makes your place special.</p>
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                    <input className='form-control' type="text" />
                                    <p className='f-14 text-muted mt-2'>0 / 50</p>
                                    <hr/>
                                    <div className='d-flex jc-between'>
                                        <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                        <button className="bnn btn-theme">Save</button>
                                    </div>
                                </div>
                            </div>
                            <hr className='themehr my-4' />

                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Listing Description</h6>
                                        <p className='text-muted f-14'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque doloremque distinctio consequatur, officiis sapiente, reiciendis voluptate quod nesciunt blanditiis nemo ut vitae neque quisquam. Aliquam reprehenderit eligendi sit nisi ipsam!</p>
                                    </div>
                                    <Button className='text-dark' variant="link" onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Listing Description</b></p>
                                            <p className='f-12'>Your listing title should highlight what makes your place special.</p>
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                <textarea className='w-full form-control' style={{height: "100px"}}></textarea>
                                <p className='f-14 text-muted mt-2'>0 / 500</p>
                                <hr/>
                                    <div className=' mb-4'>
                                        <p className='f-14'><b>The space</b></p>
                                        <p className='f-12'>Provide a general description of what the property and rooms are like so guests know what to expect.</p>
                                    </div>
                                    <textarea className='w-full form-control mb-5' style={{height: "100px"}}></textarea>
                                <hr/>
                                <div className=' mb-4'>
                                        <p className='f-14'><b>Guest access</b></p>
                                        <p className='f-12'>Let guests know which parts of the space they’ll be able to access.</p>
                                    </div>
                                    <textarea className='w-full form-control mb-5' style={{height: "100px"}}></textarea>
                                <hr/>
                                <div className=' mb-4'>
                                        <p className='f-14'><b>Other details to note</b></p>
                                        <p className='f-12'>Include any special info you want potential guests to know before booking that isn't covered in other settings.</p>
                                    </div>
                                    <textarea className='w-full form-control mb-5' style={{height: "100px"}}></textarea>
                                <hr/>
                                <div className='d-flex jc-between'>
                                    <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                    <button className="bnn btn-theme">Save</button>
                                </div>
                            </div>
                            </div>

                            <hr className='themehr my-4'/>

                            <div className='blocks my-sm-4 my-3'>
                                <div className='d-flex align-items-center jc-between'>
                                    <h6 className='mb-0'>Number of guests</h6>
                                  <div>
                                    <button className='bg-white circlebtn'>-</button>
                                    <span className='mx-3'>4</span>
                                    <button className='bg-white circlebtn'>+</button>
                                  </div>
                                </div>
                            </div>
                           
                            <hr className='themehr my-4'/>

                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Custom link</h6>
                                        <p className='text-muted f-14'>Not set</p>
                                    </div>
                                    <Button onClick={(e) => hideBlock(e)} className='text-dark' variant="link">Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none'>
                                    <div className='d-flex jc-between mb-'>
                                        <div>
                                            <p className='f-14'><b>Custom link</b></p>
                                           
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                    <div>

                                    
                                    <input className='form-control mb-2' type="text" />
                                    <p className='f-12'>A memorable and unique link can make it easy to share your listing on business cards, websites, or social media. E.g.: JetToLet.com/h/private-room-in-barcelona-centre.</p>
                                    <p className='f-14 text-muted mt-2'>0 / 50</p>
                                <hr/>
                                <div className='d-flex jc-between'>
                                    <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                    <button className="bnn btn-theme">Save</button>
                                </div>
                                </div>
                                
                                </div>
                            </div>
                            
                            <hr className='themehr my-4'/>

                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Languages</h6>
                                        <p className='text-muted f-14'>English (Default)</p>
                                    </div>
                                    <Button className='text-dark' variant="link" onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none'>
                                    <div className='d-flex jc-between mb-'>
                                        <div>
                                            <p className='f-14'><b>Languages</b></p>
                                            <p className='f-12 text-muted'>Write details for some settings in a language other than your default. Guests are shown
                                                 automatic translations for the languages you don’t add here.</p>
                                           
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                    <div>
                                        <p className='text-muted mt-3'>English(Default)</p>
                                    </div>
                                    <hr />
                                   <select className='form-control'>
                                    <option>Add language</option>
                                    <option>English</option>
                                    <option>한국어</option>
                                    <option>Français (canadien)</option>
                                   </select>
                                    <p className='f-14 text-muted mt-2'>0 / 50</p>
                                   <hr/>
                                <div className='d-flex jc-between'>
                                    <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                    <button className="bnn btn-theme">Save</button>
                                </div>
                                
                                </div>
                            </div>

                            <hr className='themehr my-4'/>

                            <div className='blocks'>
                                 <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Listing status</h6>
                                        <p className='text-muted f-14'><span className='fa fa-circle text-success f-12'></span> Listed - Guests can find your listing in search results and request or book available dates.</p>
                                    </div>
                                    <Button className='text-dark' variant="link">Edit</Button>
                                </div>
                            </div>
                           
                        </div>
                        <hr className='themehr my-4'/>
                        <div id="amenities">
                           <div className='blocks'>  
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h5>Amenities</h5>
                                        <div className='d-grid'>
                                            <p>Essentials</p>
                                            <p>Cleaning products</p>
                                            <p>Air conditioning</p>
                                            <p>Cooking basics</p>
                                        </div>
                                    </div>
                                    <Link to="/hosting/managespace/amenities"  className="text-dark">Edit  <span className='fa fa-chevron-right ms-2 f-14'></span></Link>
                                    {/* <Button className='text-dark' variant="link">Edit <span className='fa fa-chevron-right f-14 ms-2'></span></Button> */}
                                </div>
                               
                            </div>
                        </div>
                         <hr className='themehr my-4'/>
                        <div className='basiclists' id="location">
                            <div className='blocks'>
                                <h5>Location</h5>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Address</h6>
                                        <p className='text-muted f-14'>Alangudi Rd, Marthandapuram, Tamil Nadu 622303, India</p>
                                    </div>
                                    <Button className='text-dark' variant="link" onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none my-5'>
                                    <div className='d-flex jc-between mb-'>
                                        <div>
                                            <p className='f-14'><b>Languages</b></p>
                                            <p className='f-12 text-muted'>Write details for some settings in a language other than your default. Guests are shown
                                                 automatic translations for the languages you don’t add here.</p>
                                           
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                   
                                   <label className='text-muted mt-4'>Street</label>
                                    <input type="text" className="form-control" />
                                    <p className='f-12 text-muted mt-2'>House name/number + street/road</p>
                                 
                                    <label className='text-secondary mt-4'>Flat (optional)</label>
                                    <input type="text" className="form-control" />
                                    <p className='f-12 text-muted mt-2'>Flat, suite, building access code</p>
                                    <div className='d-flex flex-1 gap-3 mb-3'>
                                        <div className=''>
                                            <label className='text-muted mt-4'>City</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <div>
                                            <label className='text-muted mt-4'>Contry</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className='w-50'>
                                        <label className='text-muted mt-4'>City</label>
                                        <input type="text" className="form-control" />
                                    </div>
                                    <hr />

                                <div className='d-flex jc-between'>
                                    <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                    <button className="bnn btn-theme">Save</button>
                                </div>
                                
                                </div>
                            </div>
                            
                            <hr className='themehr my-4'/>

                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Neighbourhood description</h6>
                                        <p className='text-muted f-14'>Not set</p>
                                    </div>
                                    <Button className='text-dark' onClick={(e) => hideBlock(e)} variant="link">Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none my-4'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Neighbourhood description</b></p>
                                            <p className='f-12 text-muted'>Write details for some settings in a language other than your default. Guests are shown
                                            automatic translations for the languages you don’t add here.</p>
                                        </div>
                                          <button className='x-btn fa fa-times f-14 ' onClick={(e) => showBlock(e)}></button>

                                        
                                    </div>
                                    <textarea className='w-full form-control' style={{height: "100px"}}></textarea>
                                    <hr />
                                    <div className='d-flex jc-between'>
                                <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                <button className="bnn btn-theme">Save</button>
                                </div>
                                </div>
                            </div>
                            
                            <hr className='themehr my-4'/>

                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Getting around</h6>
                                        <p className='text-muted f-14'>Not set</p>
                                    </div>
                                    <Button className='text-dark' onClick={(e) => hideBlock(e)} variant="link">Edit</Button>
                                </div>
                                <div className='bordbox p-sm-4 br-10 h-55 d-none my-4'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Getting around</b></p>
                                            <p className='f-12 text-muted'>Let guests know how they can get around the neighbourhood and what parking is like.</p>
                                        </div>
                                           <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>

                                        
                                    </div>
                                    <textarea className='w-full form-control' style={{height: "100px"}}></textarea>
                                    <hr />
                                    <div className='d-flex jc-between'>
                                <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                <button className="bnn btn-theme">Save</button>
                                </div>
                            </div>
                            </div>
                            
                            <hr className='themehr my-4'/>
                          
                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div >
                                        <h6>Location sharing</h6>
                                        <p className='text-muted f-14'>General location</p>
                                        <p className='text-muted f-14'>Address privacy for cancellations off</p>
                                    </div>
                                    <Button className='text-dark' variant="link" onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>

                                <div className='bordbox p-sm-4 br-10 d-none my-4'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Location sharing</b></p>
                                            <p className='f-12 text-muted'>Choose how your listing’s location is displayed to guests before they book.</p>
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                    <div class="form-check gap-3 justify-content-end"> 
                                        <label class="form-check-label " for="flexRadioDefault2">
                                            <p>General location</p>
                                            <p className="f-14 text-muted">Show only the general area to anyone searching for a place to stay.</p>
                                        </label>
                                        <div className='sharing'>
                                            <img src={img17} />
                                            <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        </div>
                                    </div>
                                    <div class="form-check gap-3 justify-content-end mt-3"> 
                                        <label class="form-check-label " for="flexRadioDefault2">
                                            <p>Specific location</p>
                                            <p className="f-14 text-muted">Show a more specific location to guests searching for a place to stay.</p>
                                        </label>
                                        <div className='sharing'>
                                            <img src={img17} />
                                            <input class="form-check-input me-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                                        </div>
                                    </div>
                                    <hr/>
                                    <p className='text-muted'>
                                        The circle will be shown on your listing. Only guests with a confirmed reservation will be able to see the pin. If you need to, you can 
                                        drag the pin to adjust its location.
                                    </p>
                                    <div className="mapComponent">
                                        <MyMapComponent />
                                        </div> 
                                    <hr/>
                                    <div className='d-flex justify-content-between align-items-center mt-5'>
                                <div>
                                    <p>Address privacy for cancellations</p>
                                    <p className='text-muted'>When this setting is on, guests won't be shown your address, last name, or phone number while they’re able to cancel for free. After the free cancellation period, we’ll 
                                        send guests this information.</p>
                                </div> 
                                <hr />
                                <div class="form-switch">
                                    <input type="checkbox" id="custom-switch" class="form-check-input"/>
                                </div>
                            </div>   
                            <hr />
                                    <div className='d-flex jc-between'>
                                        <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                        <button className="bnn btn-theme">Save</button>
                                    </div>
                                </div>
                            </div>

                            <hr className='themehr my-4'/>

                        <div className='blocks'>
                            <div className='d-flex jc-between my-4'>
                                <div>
                                    <h6>Scenic views</h6>
                                    <p className='text-muted f-14'>Not set</p>
                                    <div class="d-flex gap-3">
                                        <div class="">
                                            <p class="text-muted f-14">Bay view</p>
                                            <p class="text-muted f-14">Beach view</p>
                                        </div>
                                        <div>
                                            <p class="text-muted f-14">Bay view</p>
                                            <p class="text-muted f-14">Canal view</p>
                                        </div>
                                    </div>
                                </div>
                                <Button onClick={() => setScenic(true)} className='text-dark' variant="link">Edit</Button>
                            </div>
                        </div>

                            <hr className='themehr my-4'/>
                     </div>
                     <div className='basiclists' id="listing-basic">
                    
                            <h5>Property and rooms</h5>
                            <div className='blocks'>
                                <div className='d-flex jc-between my-4'>
                                    <div>
                                        <h6>Property type</h6>
                                        <p className='text-muted f-14'>Holiday home</p>
                                        <p className='text-muted f-14'>Listing type: Shared room</p>
                                    </div>
                                    <Button className='text-dark' variant="link" onClick={(e) => hideBlock(e)}>Edit</Button>
                                </div>  
                                <div className='bordbox p-sm-4 br-10 h-55 d-none my-4'>
                                    <div className='d-flex jc-between mb-4'>
                                        <div>
                                            <p className='f-14'><b>Property type</b></p>
                                            <p className='f-12 text-muted'>Choose a property type that’s most like your place to set expectations for guests and help your 
                                            listing appear in the right searches.</p>
                                        </div>
                                        <button className='x-btn fa fa-times f-14' onClick={(e) => showBlock(e)}></button>
                                    </div>
                                
                                <label className=' mb-2'>Which is most like your place?</label>
                                <select className='form-control'>
                                        <option>Flat</option>
                                        <option>House</option>
                                        <option>Secondary unit</option>
                                        <option>Unique space</option>
                                        <option>Bed and breakfast</option>
                                        <option>Boutique hotel</option>
                                    </select>

                                    <label className='mt-4 mb-2'>Property type</label>
                                <select className='form-control'>
                                        <option>Home</option>
                                        <option>Townhouse</option>
                                        <option>Bungalow</option>
                                        <option>Cabin</option>
                                        <option>Chalet</option>
                                        <option>Earthen home</option>
                                        <option>Lighthouse</option>
                                    </select>

                                    <label className='mt-4 mb-2'>Listing type</label>
                                <select className='form-control'>
                                        <option>Entire place</option>
                                        <option>Private room</option>
                                        <option>Shared room</option>

                                    </select>
                                    <hr />
                                    <label className='mt-4 mb-2'>How many floors does the building have?</label>
                                    <input type="text" className='form-control' />
                                    
                                    <label className='mt-4 mb-2'>Which floors is the listing on?</label>
                                    <input type="text" className='form-control mb-5' />
                                    <hr />
                                    <div className='d-flex jc-between'>
                                        <Button variant="link" className='text-dark ps-0'>Cancel</Button>
                                        <button className="bnn btn-theme">Save</button>
                                    </div>
                                </div>  
                            </div>
                       
                       
                       
                            
                            <hr className='themehr my-4'/>
                            <div className='d-flex jc-between my-4'>
                                <div>
                                    <h6>Rooms and Spaces</h6>
                                    <p className='text-muted f-14'>Not set</p>
                                    <p className='text-muted f-14'>Bedrooms: 1</p>
                                    <p className='text-muted f-14'>Bed: 1</p>
                                    <p className='text-muted f-14'>Bathrooms: 1</p>
                                    <p className='text-muted f-14'>Additional areas: 0</p>
                                    <p className='text-muted f-14'>Guests may share some areas</p>
                                </div>
                                <Link to="/hosting/managespace/rooms-and-spaces"Button className='text-dark'>Edit<span className='fa fa-chevron-right f-14'></span></Link>
                            </div>
                            <hr className='themehr my-4'/>
                    </div>
                            <div className='basiclists' id="listing-basic">
                        
                        <div className='d-flex jc-between my-4'>
                            <div>
                                <h5>Accessibility</h5>
                            </div>
                            <Button className='text-dark' variant="link">Edit</Button>
                        </div>
                        <hr className='themehr my-4'/>
                        <div className='d-flex jc-between my-4'>
                            <div>
                                <h5>Guest safety</h5>
                                <div className='d-flex gap-3'>
                                    <div className=''>
                                        <p className='text-muted f-14'>Security cameras/audio recording devices</p>
                                        <p className='text-muted f-14'>Carbon monoxide alarm</p>
                                        <p className='text-muted f-14'>Smoke alarm</p>
                                    </div>
                                    <div>
                                    <p className='text-muted f-14'>Weapons on property</p>
                                    <p className='text-muted f-14'>Potentially dangerous animal</p>
                                    </div>
                                </div>
                            </div>
                            <Button className='text-dark' variant="link">Edit</Button>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ManageSpace