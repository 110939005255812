import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { getExperienceFromLocal } from '../../../../Hooks/useUserExperienceHook';
import Congrats from '../Modals/Congrats';

const ReviewExperience = (props) =>{
    const[congrats, setCongrats] = useState(false);
    const [qualityStandards , setQualityStandards] = useState(false);
    const [licensing , setLicensing] = useState(false);
    const [value, setValue] = useState({qualityStandards:false,licensing:false});
    const [btn , setBtn] = useState(false);

    const setData = (e) =>{
        if(e.target.id == "flexCheckChecked1"){
            value.qualityStandards = e.target.checked;
            setQualityStandards(e.target.checked)
        }else if(e.target.id == "flexCheckChecked2"){
            value.licensing = e.target.checked;
            setLicensing(e.target.checked)
        }
        setValue(value);
    }

    useEffect(()=>{
        const local = getExperienceFromLocal();
        if(local && local.termsAndConditions){
            setQualityStandards(local.termsAndConditions.qualityStandards);
            setLicensing(local.termsAndConditions.licensing);
        }
    },[]);

    return(
        <>
        {congrats && <Congrats onDismiss={() => setCongrats(false)}/>}
                <div>
                    <h2 className='conthead'>Review your experience one last time</h2>

                    <p className='text-muted f-14'>To qualify to be on JetToLet, you're responsible for your experience, so take one last look and make sure:
                    </p>
                    <div className='mt-4'>
                            
                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14" for="flexCheckChecked1">
                            You meet JetToLet's quality standards.
                        </label>
                        <input class="form-check-input" checked={qualityStandards} onChange={(e)=>{setData(e)}} type="checkbox" value="Split-type ductless system" id="flexCheckChecked1" />
                    </div>
                          
                    <div class="form-check my-3 align-items-start justify-content-end gap-2">
                        <label class="form-check-label f-14" for="flexCheckChecked2">
                            Your experience compiles with local laws business licensing and other local laws that may apply to your experience.
                        </label>
                        <input class="form-check-input" checked={licensing} onChange={(e)=>{setData(e)}} type="checkbox" value="Split-type ductless system" id="flexCheckChecked2" />
                    </div>

                        <div className='mt-4'><button className='btn btn-theme' disabled={!(qualityStandards&&licensing) || btn} onClick={() => {setBtn(true);setCongrats(true);props.onSetLocal(value,"termsAndConditions")}}>Submit</button></div> 
                    </div>
                    
              </div>    
         
        </>

    )
}

export default ReviewExperience