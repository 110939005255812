import React, { useState } from 'react';
import {Breadcrumb,Button, Form} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../../../Common/Footer';
import Header from '../../../Common/Header';
import eye from '../../../../Assets/images/eye.png'
import bag from '../../../../Assets/images/bag.png'

import color from '@material-ui/core/colors/amber';


const AccGlobalPerform = () => {
    const[show , setShow] = useState(0)
    const[showadd, setShowAdd] = useState(false)
    const[addanother, setAddAnother] = useState(false)

    const [toggle,settoggle] = useState(true);

    const removeBlur = (count) =>{
        document.querySelectorAll(".blur_ul li").forEach(function(a){
            console.log("a",a);
            toggle?  (a.style.opacity = "0.5") : (a.style.opacity = "1")
            

            // toggle?  (a.style.cu = "0.5") : (a.style.opacity = "1")
            
        });
        document.querySelectorAll(".blur_ul li button.editbtn").forEach(function(a){
            a.style.pointerEvents = "none";
        }); 
        var k =  document.getElementById("cancel_"+count);

        console.log("toggggggg",toggle)
        toggle? (k.style.pointerEvents = "unset") : 
        document.querySelectorAll(".blur_ul li button").forEach(function(a){
            a.style.pointerEvents = "unset";
        }); 

        
        console.log(document.querySelector(".blur_ul li"+[count]),"fdsf")
        document.getElementById("blur_"+count).style.opacity = "1";
        toggle?  (document.getElementById("cancel_"+count).innerText = "Cancel") : (document.getElementById("cancel_"+count).innerText = "Edit")

        settoggle(!toggle);
        toggle?setShow(count):setShow("");
       
    }
    const[lang, setLang] = useState("Enlish")
    const[currency, setCurrency] = useState("Female")
    const[time, setTime] = useState("")
  
    const changevalue = (data) =>{
        if(data == "one"){
            var a = document.getElementById("selectedlang").value;
            setLang(a)
        }
        if(data == "two"){
            var a = document.getElementById("selectedcurrency").value;
            setCurrency(a)
        }else{
            var a = document.getElementById("selectedtime").value;
            setTime(a)
        }
        
    }
    return(
        <div className='personalinfo nocenterhead'>
            <Header />
            <div className='mh-100vh'>
            <div className='container container_custom mt-78'>
                <div className='row justify-content-center'>
                
                    <div className='col-xl-9'>
                    <div className='breadcrum mt-4'>
                        <Breadcrumb>
                        <Breadcrumb.Item><Link to="/">Acocount</Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/">Global Preferences</Link></Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>Global Preferences</h2>
                    </div>
                        <div className='row mt-5 justify-content-between'>
                            <div className='col-md-7'>
                                <div className='cont'>
                                    <ul className='ps-0 blur_ul'>
                                  
                                        <li id="blur_1">
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-0'>Preferred language</p>
                                                
                                                <Button id="cancel_1" variant="link" className='p-0 cancelbtn editbtn' onClick={() => removeBlur(1)}>Edit</Button>
                                            </div>
                                            <div className={show ==1 ? "edited show" : "edited"}>
                                                <p className='f-14 text-muted'>{lang}</p>
                                                <div className='editedcont'>
                                                    <div className='d-flex mt-4'>
                                                        <div className='floating-label w-full me-2'>
                                                            <select className='form-control' id="selectedlang">
                                                                <option>English</option>
                                                                <option>Korean</option>
                                                                <option>Spanish</option>
                                                            </select>
                                                            {/* <input className='form-control me-2' value="AshwinKumar" type="text"/> */}
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='mt-4'>
                                                        <button className='btn btn-theme' onClick={() => changevalue("one")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='themehr'/>
                                        </li>
                                      
                                        <li id="blur_2">
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-0'>Preferred currency</p>
                                                
                                                <Button id="cancel_2" variant="link" className='p-0 cancelbtn editbtn' onClick={() => removeBlur(2)}>Edit</Button>
                                            </div>
                                            <div className={show ==2 ? "edited show" : "edited"}>
                                                <p className='f-14 text-muted'>{currency}</p>
                                                <div className='editedcont'>
                                                    <div className='d-flex mt-4'>
                                                        <div className='floating-label w-full me-2'>
                                                         
                                                            <select className='form-control ' id="selectedcurrency">
                                                                <option>Female</option>
                                                                <option>Male</option>
                                                                <option>hgkg</option>
                                                            </select>
                                                            {/* <input className='form-control me-2' value="AshwinKumar" type="text"/> */}
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='mt-4'>
                                                        <button className='btn btn-theme' onClick={() => changevalue("two")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='themehr'/>
                                        </li>
                                        <li id="blur_3">
                                            <div className='d-flex justify-content-between'>
                                                <p className='mb-0'>Time zone</p>
                                                
                                                <Button id="cancel_3" variant="link" className='p-0 cancelbtn editbtn' onClick={() => removeBlur(3)}>Edit</Button>
                                            </div>
                                            <div className={show ==3 ? "edited show" : "edited"}>
                                            <p className='f-14 text-muted'>{time}</p>

                                                <div className='editedcont'>
                                                    <div className='d-flex mt-4'>
                                                        <div className='floating-label w-full me-2'>
                                                         
                                                            <select className='form-control' id="selectedtime">
                                                                <option>(GMT+09:00) Seoul</option>
                                                                <option>(GMT-10:00) Hawaii</option>
                                                                <option>(GMT+08:00) Ulaan Bataar</option>
                                                            </select>
                                                            {/* <input className='form-control me-2' value="AshwinKumar" type="text"/> */}
                                                        </div>
                                                       
                                                    </div>
                                                    <div className='mt-4'>
                                                        <button className='btn btn-theme' onClick={() => changevalue("three")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className='themehr'/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-5 d-md-block d-none '>
                                <div className='right-cont bord p-4 br-10'>
                                    <img src={bag} className="mb-3"/>
                                    <h6>Which details can be edited?</h6>
                                    <p className='text-muted'>Details JetToLet uses to verify your identity can’t be changed. 
                                        Contact info and some personal details can be edited, but we may
                                         ask you to verify your identity the next time you book or create a listing.</p>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default AccGlobalPerform