import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { API_URL } from "../../../config/env";

const EditDescribePlace = (props) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedValue, setSelectedValue] = useState({});
  
    const setData = (val, index) => {
      setSelectedIndex(index);
      setSelectedValue(val);
      localStorage.setItem("EditdescribePlaceIndex", index);
      props.onGetDescribePlace(val);
    };
  
    useEffect(() => {
      setSelectedIndex(
        localStorage.getItem("EditdescribePlaceIndex")
          ? localStorage.getItem("EditdescribePlaceIndex")
          : selectedIndex
      );
      localStorage.getItem("EditpropertyType")
        ? props.onGetDescribePlace(
          JSON.parse(localStorage.getItem("EditpropertyType"))
        )
        : localStorage.removeItem("EditdescribePlaceIndex");
    }, []);


    useEffect(() => {
        console.log('props?.describe?.rommdata', props?.roomdata?.propertyType)
        if (props?.roomdata) {
            let index = Number(props?.roomdata?.propertyType?.id) - 1;
            let setIndex = localStorage.getItem("EditdescribePlaceIndex") != null ? localStorage.getItem("EditdescribePlaceIndex") : props?.roomdata?.propertyType ? index : selectedIndex;
            localStorage.getItem("EditpropertyType") != null ? props.onGetDescribePlace(JSON.parse(localStorage.getItem("EditpropertyType"))) :
            props?.roomdata?.propertyType ? props.onGetDescribePlace(props?.roomdata?.propertyType) : localStorage.removeItem("EditdescribePlaceIndex")
            setSelectedIndex(setIndex)
        }
    }, [localStorage.getItem("placeTypeIndex"), props])
  
    return (
      <div className="col-lg-12 col-xl-12 col-md-12">
        <div className="placetype rightcont mb-5 describeplace">
          <Scrollbars
            style={{ height: "calc(100vh - 320px)" }}
            className="scroll"
          >
            <div
              className="row  px-3 align-items-center height-100 mb-5 py-2"
              data-aos="fade-up"
            >
              <ul>
                {props.data &&
                  props.data.content.map((val, index) => (
                    val?.status != "Inactive" && <li>
                      <button
                        onClick={() => {
                          setData(val, index);
                          props?.Filter("propertyType")
                        }}
                        className={
                          index == selectedIndex
                            ? "placecards active custom_img_icon"
                            : "placecards custom_img_icon"
                        }
                      >
                        {console.log("Image_path",` ${API_URL}${props?.data?.path}/${val?.image}`)}
                      <img src={`${API_URL}${props?.data?.path}/${val?.image}`} alt='icon' className="img-fluid" />
                        <div>
                          <h6 className="mb-0">{val.heading}</h6>
                          <p className="f-14">{val.content}</p>
                        </div>
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  };
  
  export default EditDescribePlace;
