import React, { useState, useEffect } from "react";
import { Button, InputGroup, Form } from "react-bootstrap";
import { io } from 'socket.io-client';

import SockJS from 'sockjs-client';
//ws
// import WebSocket from 'ws';
//
import { Link, useLocation, useNavigate } from "react-router-dom";
import prof from "../../../Assets/images/prof.jpg"
import nochat from "../../../Assets/images/no_chat.png"
import prof2 from "../../../Assets/images/prof2.jpg"
import prof3 from "../../../Assets/images/prof3.jpg"

import chat1 from "../../../Assets/images/chat1.png"
import chat2 from "../../../Assets/images/chat2.png"
import chat3 from "../../../Assets/images/chat3.png"
import chat4 from "../../../Assets/images/chat4.png"
import chat5 from "../../../Assets/images/chat5.png"
import chat6 from "../../../Assets/images/chat6.png"

import HostHeader from "./HostHeader";
import FilterMessage from "./Modals/FilterMessage";
import SpecialOffer from "./Modals/SpecialOffModal";
import ReserveDetails from "./ReserveDetail";
import { API_URL } from "../../../config/env";
import { getUserDatas } from "../../../Hooks/useUserHook";
import { getChatRoomMessages, getChatUsers } from "../../../Hooks/chatHook";
import { isEmpty } from "lodash";
import { DateTimeForm } from "../../../Hooks/UseValidation";
const WebSocketClient = require('websocket');
var W3CWebSocket = require('websocket').w3cwebsocket;
const Inbox = () => {
    const location = useLocation();
    const [specialoffer, setSpecialOffer] = useState(false)
    console.log('specialoffer', specialoffer)
    const [filtermsg, setFilterMsg] = useState(false)
    console.log('filetermdagta', filtermsg)
    const [showcont, setShowcont] = useState(false)
    console.log('showcont', showcont)

    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [userData, setUserData] = useState({});
    const [contactList, setContactList] = useState([]);
    const [chatRoomMessages, setChatRoomMessages] = useState([]);
    const [receiver, setReceiver] = useState("");
    const [message, setMessage] = useState("");
    console.log('messageeeeeeee', message)
    const [sent, setSent] = useState(false);
    const [filter, setFilter] = useState(false);
    const [filteredContactList, setFilteredContactList] = useState([]);
    const [refreceiver , setRefreceiver] = useState({});
    const [search , setSearch] = useState("");
    const [textcount , setTextcount] = useState(0);

    const [image , setImage] = useState("");
    const [imageblob , setImageblob] = useState("");
    const navigate = useNavigate();
    

    const edittxt = (e) => {
        const txt = e.target.value
        console.log('txtdata', txt)
        const lines = txt.split("/n")
        const txtcount = lines.length;
        setMessage(txt);
        setTextcount(txt?.length);
        console.log('linedata', lines)
    }


    const closereserve = () => {
        document.getElementById("reserve").style.left = "100%"
    }
    const ShowReserve = () => {
        document.getElementById("reserve").style.left = "-3px"

    }

    const socket = io(API_URL);
    // const socket = new W3CWebSocket(API_URL);
    // const socket = new SockJS(API_URL);
    // socket.connect(API_URL);
    
    const getData = () => {
        const user = getUserDatas();
        // setFilter(false);
        // setFilteredContactList([]);
        console.log('userdatasaaaaaa', user)
        setUserData(user)
        getChatUsers(user?.email).then((res) => {
            console.log("data in getChatUser:", res);

            if(search){
                setFilter(true);
                const filteredData = res.filter(data => {
                    console.log("data inside filter :", data, "e", search);
                    return data?.firstName.toLowerCase().includes(search.toLowerCase())
                })
                console.log("filteredData :", filteredData);
                setFilteredContactList(filteredData);
            }

            setContactList(res);
            if(window.location.pathname.split("/")[4]){
                setShowcont(true)
                setReceiver(window.location.pathname.split("/")[4]);
                var ref = res.find(e => e?.email?.toLowerCase() == window.location.pathname.split("/")[4].toLowerCase())
                setRefreceiver(ref)
                console.log("getChatRoomMessages data:" , ref);
                const datas = {
                    receiverId: window.location.pathname.split("/")[4],
                    senderId: window.location.pathname.split("/")[3] //user?.email
                }
                getChatRoomMessages(datas).then((data) => {
                    setChatRoomMessages(data);
                    console.log("getChatRoomMessages data:", data);
                })
            }
        })
        
    }

    const getChatRoomDatas = (receiver) => {
        console.log("receiver :", receiver, "sender :", userData?.email);
        setReceiver(window.location.pathname.split("/")[4]);
        var ref = contactList.find(e => e?.email?.toLowerCase() == window.location.pathname.split("/")[4].toLowerCase())
        setRefreceiver(ref)
        const datas = {
            receiverId: window.location.pathname.split("/")[4],
            senderId: userData?.email
        }
        getChatRoomMessages(datas).then((data) => {
            setChatRoomMessages(data);
            console.log("getChatRoomMessages data:", data);
        })
        getData();
    }

    const filterMessage = (e) => {
        setFilter(true);
        setSearch(e?.target?.value)
        const filteredData = contactList.filter(data => {
            console.log("data inside filter :", data, "e", e.target.value);
            return data?.firstName.toLowerCase().includes(e.target.value.toLowerCase())
        })
        console.log("filteredData :", filteredData);
        setFilteredContactList(filteredData);
    }

    useEffect(() => {
        let receiverid;
        console.log("location.state :", location?.state);
        if (location.state) {
            receiverid = location?.state
        } else {
            receiverid = receiver
        }
        const data = {
            receiverId: receiverid,
            senderId: userData?.email
        }
        getChatRoomMessages(data).then((data) => {
            setChatRoomMessages(data);
            setSent(false);
            console.log("getChatRoomMessages data:", data);
        })
    }, [sent, arrivalMessage]);

    const sendMessage = async () => {
        console.log("receivereeeeee" , window.location.pathname.split("/"));
        if(message){
            // await
             socket.emit("sendMessage", {
                senderId: userData?.email,
                receiverId: window.location.pathname.split("/")[4],
                content: message,
                image : image
            });
            setMessage("");
            setImage("");
            setImageblob("");
            setSent(true);
            getData()
        }
    }

    useEffect(() => {
        console.log("inside getMessage useEffect");
        socket.on("getMessage", (data) => {
            console.log("getMessage, data :", data);
            setArrivalMessage({
                sender: data?.senderId,
                content: data?.content,
                createdAt: Date.now(),
            });
            getData()
        });

        return() => {
            socket.off("getMessage");
        }
    }, [arrivalMessage , socket]);

    useEffect(() => {
        if (userData.email != null) {
            console.log("socket :", socket);
            socket.emit("addUser", userData?.email);
        }
        socket.on("getUsers", users => {
            console.log("socket getUsers, users", users);
        })
        return() => {
            socket.off("getUsers");
        }
    }, [userData._id , socket])

    useEffect(() => {
       socket.onmessage = function(data){
        console.log("data in checking socket" , data);
          socket.send("send" , "DATA");
       }
    }, [socket])

    useEffect(() => {
        getData();
        
    }, []);


    const month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUGUST", "SEP", "OCT", "NOV", "DEC"];


    const StaticContent = [
        {
            icon:chat1,
            name:'Luxury villa',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
            active:true
        },
        {
            icon:chat2,
            name:'Prime vista',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
        },
        {
            icon:chat3,
            name:'Luxury villa',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
        },
        {
            icon:chat4,
            name:'Moon view',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
        },
        {
            icon:chat5,
            name:'Lake view resort',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
        },
        {
            icon:chat6,
            name:'Royal meridian ',
            des:'Lorem ipsum dolor sit...',
            time:'10:10 am',
        }

    ]
    return (
        <div id="messages">


            {specialoffer && <SpecialOffer onDismiss={() => setSpecialOffer(false)} />}
            {filtermsg && <FilterMessage onDismiss={() => setFilterMsg(false)} />}
            <HostHeader />
            <div className="container container_custom">
                <div className="row position-relative overflow-hidden">
                    <div className="col-lg-3 col-md-4 pe-0">
                        <div className="">
                            <div className="d-flex align-items-center mt-3 mb-2 ps-3">
                                {/* <Button variant="link"><span className="fa fa-bars"></span></Button> */}
                                <h4 className="mb-0">All messages</h4>
                            </div>
                            <div className="d-flex px-3 mb-3">
                                <InputGroup className=" searchbtn">
                                    <InputGroup.Text id="basic-addon1"><span className="fa fa-search"></span></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Username"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        onChange={(e) => { filterMessage(e) }}
                                    />
                                </InputGroup>
                                {/* <Button variant="link" onClick={() => setFilterMsg(true)}><span className="fa fa-filter text-dark"></span></Button> */}
                            </div>
                            <div className="messages">
                                {/* {
                                    StaticContent.map((item) => {
                                        return (


                                            <>
                                                <li className={item.active ? "chat-active-bg" : ""}>
                                                    <a onClick={() =>{getChatRoomDatas(item); setShowcont(true)}} href="javascript:void(0)" className="text-dark nounder">

                                                        <img alt="" src={prof} /> 
                                                        
                                                        <div className="d-flex jc-between w-full ps-2">
                                                            <div>
                                                                <p className="f-12 chat-name">{item.name}</p>
                                                                <p className="chat-description f-12">{item.des}</p>
                                                            </div>
                                                            <p className="text-muted f-12">{item.time}</p>
                                                        </div>
                                                    </a>
                                                </li>
                                            </>
                                        )
                                    })
                                } */}
                            {(!filter) ? contactList.length > 0 && contactList?.map((item) =>

                                        // <li>
                                        //     {console.log('vallllllllllllllllllllllll', val)}

                                        //     <a onClick={() => { getChatRoomDatas(val); setShowcont(true) }} href="javascript:void(0)" className="text-dark nounder">
                                        //         <img alt="" src={prof} />
                                        //         <div className="d-flex jc-between w-full">
                                        //             <div>
                                        //                 {/* <p className="f-12 text-success chat-name">Enquiry</p> */}
                                        //                 <p className="text-muted chat-description f-12">{val}</p>
                                        //                 {/* <p className="text-muted f-12">28-29 Aug <span>Just for testing</span></p> */}
                                        //             </div>
                                        //             {/* <p className="text-muted f-12">Yesterday</p> */}
                                        //         </div>
                                        //     </a>
                                        // </li>
                                        <>
                                                <li className={item?.seen ? "chat-active-bg" : ""}>
                                                    <a onClick={() =>{
                                                        // window.location.href = window.location.origin + "/host-dashboard/inbox/"+userData?.email+ "/"+item?.email 
                                                        navigate(`/host-dashboard/inbox/${userData?.email}/${item?.email}`)
                                                        getChatRoomDatas(item); 
                                                        setShowcont(true)}} href="javascript:void(0)" className="text-dark nounder">
                                                        <img alt="" src={prof} /> 
                                                        {/* item.icon */}
                                                        <div className="d-flex jc-between w-full ps-2">
                                                            <div>
                                                                <p className="f-12 chat-name">{item?.firstName + " " + item?.lastName}</p>
                                                                {/* <p className="chat-description f-12">{item.lastName}</p> */}
                                                                {/* <p className="text-muted f-12">28-29 Aug <span>Just for testing</span></p> */}
                                                            </div>
                                                            {/* <p className="text-muted f-12">{item.time}</p> */}
                                                            <span className={item?.seen ? "chat-active-notification" : "" }></span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </>
                                        ) :
                                    filteredContactList.map((item) =>
                                        // <li>
                                        //     <a onClick={() => { getChatRoomDatas(val); setShowcont(true) }} href="javascript:void(0)" className="text-dark nounder">
                                        //         <img alt="" src={prof} />
                                        //         <div className="d-flex jc-between w-full">
                                        //             <div>
                                        //                 {/* <p className="f-12 text-danger chat-name">Enquiry</p> */}
                                        //                 <p className="text-muted chat-description f-12">{val}</p>
                                        //                 {/* <p className="text-muted f-12">28-29 Aug <span>Just for testing</span></p> */}
                                        //             </div>
                                        //             {/* <p className="text-muted f-12">Yesterday</p> */}
                                        //         </div>
                                        //     </a>
                                        // </li>
                                        <>
                                                <li className={item?.seen ? "chat-active-bg" : ""}>
                                                    <a onClick={() =>{
                                                        // window.location.href = window.location.origin + "/host-dashboard/inbox/"+userData?.email+ "/"+item?.email 
                                                        navigate(`/host-dashboard/inbox/${userData?.email}/${item?.email}`)
                                                        getChatRoomDatas(item); 
                                                        setShowcont(true)}} href="javascript:void(0)" className="text-dark nounder">

                                                        <img alt="" src={prof} /> 
                                                        {/* item.icon */}
                                                        <div className="d-flex jc-between w-full ps-2">
                                                            <div>
                                                                <p className="f-12 chat-name">{item?.firstName + " " + item?.lastName}</p>
                                                                <p className="chat-description f-12">{item?.des}</p>
                                                                {/* <p className="text-muted f-12">28-29 Aug <span>Just for testing</span></p> */}
                                                            </div>
                                                            <p className="text-muted f-12">{item?.time}</p>
                                                            <span className={item?.seen ? "chat-active-notification" : "" }></span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="msg_bg col-lg-9 col-md-8">
                    {window.location.pathname.split("/")[4] ? 
                    <div className={showcont ? "msgcont mobilecont" : "msgcont"}>

                        <div className="message-s">
                            <div className="">
                                <div className="d-flex align-items-center pt-md-3 pt-2">
                                    <h5 className="mb-0 ps-3 d-flex align-items-center cat_wit_r">
                                        <Button variant="link" className="nounder text-dark showin767" onClick={() => setShowcont(false)}>
                                            <span className="fa fa-chevron-left"></span>
                                        </Button>Chat with {refreceiver?.firstName && refreceiver?.lastName ? refreceiver?.firstName + refreceiver?.lastName : "New User"} 
                                    </h5>
                                    {/* <Button variant="link"><span className="fa fa-star text-dark"></span></Button> */}
                                </div>

                                <div className="py-2 mt-2 mobilereseve ps-3">
                                    <Button variant="link" className="p-0 text-dark nounder" onClick={() => ShowReserve()}>
                                        <div><p className="f-12">{refreceiver?.email}</p></div>
                                    </Button>
                                </div>
                                <div className="chat-custom-bg pb-2">

                                <div><p className="f-12 ms-3 pt-4 web_re_hide">{refreceiver?.email}</p></div>
                                <div className="msg pt-4 msg_sec_scrol">
                                    <ul className="">

                                                {/* <li className="d-flex reply">
                                                    <div><img src={chat1} /></div>
                                                    <div className="d-flex justify-content-center flex-column align-items-center">
                                                        <p className="chat-rev-message"> <span className="fw-500 text-dark">    Hello!!!    </span></p>
                                                        <p className="chat-rev-time f-12 pt-1"> 10:10 am  </p>
                                                    </div>
                                                </li> 
                                                <li className="d-flex align-items-center">
                                                    <div><img src={chat2} /></div>
                                                    <div className="d-flex justify-content-center flex-column align-items-cente">
                                                        {console.log('val.senderId,val.senderId',val)}
                                                        <p className="chat-received-message"> <span className="fw-500">   Hello!!!   </span> <span className="text-muted f-14">         </span></p>
                                                        <p className="text-center chat-reveived-time f-12 pt-1">  10:10 am  </p>
                                                    </div>
                                                </li> */}


                                        {(chatRoomMessages && chatRoomMessages) ?
                                            chatRoomMessages.map((val) =>
                                                <>
                                                {userData?.email == val?.senderId ? 
                                                // <li className="d-flex reply" >
                                                //     {/* <div><img src={prof1} /></div> */}
                                                //     <div className="d-flex justify-content-center flex-column align-items-center">
                                                //         {/* {console.log('val.senderId,val.senderId', val)} */}
                                                //         <p className="chat-rev-message"> <span className="fw-500">{val?.senderId || val?.receiverId}</span> 
                                                //         {/* <span className="text-muted f-14">{new Date(val.createdAt).toLocaleDateString()}</span> */}
                                                //         </p>
                                                //         <p className="text-center chat-reveived-time f-12 pt-1">{val.content}</p>
                                                //     </div>
                                                // </li> 
                                                <li className="d-flex reply flex-column">
                                                    {/* <div><img src={chat1} /></div> */}
                                                    {val?.image &&<div className="img_chat_upload mb-3 mx-2"><img src={`${API_URL + val.image}`}></img></div>}
                                                    <div className="d-flex justify-content-center flex-column">
                                                        <p className="chat-rev-message chat-rev-message-width"> <span className="fw-500 text-dark">{val?.content}</span></p>
                                                        {/* <p className="chat-rev-time f-12 pt-1">{new Date(val?.createdAt).toLocaleDateString()}</p> */}
                                                        <p className="chat-rev-time f-12 pt-1"> {(DateTimeForm(val?.createdAt, true, false, false)).split('/')[0]} {month[new Date(val?.createdAt)?.getMonth()]} {DateTimeForm(new Date(val?.createdAt), false, true, true)}</p>
                                                    </div>
                                                </li> 
                                                : 
                                                // <li className="d-flex align-items-center">
                                                //     <div><img src={prof1} /></div>
                                                //     <div className="d-flex justify-content-center flex-column align-items-cente">
                                                //         {/* {console.log('val.senderId,val.senderId',val)} */}
                                                //         <p className="chat-received-message"> <span className="fw-500">{val?.senderId || val?.receiverId}</span> 
                                                //         {/* <span className="text-muted f-14">{new Date(val.createdAt).toLocaleDateString()}</span> */}
                                                //         </p>
                                                //         <p className="text-center chat-reveived-time f-12 pt-1">{val.content}</p>
                                                //     </div>
                                                // </li>
                                                <li className="d-flex align-items-center">
                                                    {/* <div><img src={chat2} /></div> */}
                                                    <div className="d-flex justify-content-center flex-column">
                                                        {/* {console.log('val.senderId,val.senderId',val)} */}
                                                        {val?.image &&
                                                        <div className="img_chat_upload mb-3 mx-2">
                                                        <img src={`${API_URL + val.image}`}></img></div>}
                                                        <p className="chat-received-message"> <span className="fw-500">{val?.content}</span> <span className="text-muted f-14">         </span></p>
                                                        <p className="chat-reveived-time f-12 pt-1">{new Date(val?.createdAt).toLocaleDateString()}</p>
                                                    </div>
                                                </li>
                                                }
                                                    {/* <li className="d-flex">
                            <div><img src={prof1}/></div>
                            <div>
                                {console.log('val.senderId,val.senderId',val.receiverId)}
                                <p> <span className="fw-500 text-dark">{val.receiverId}</span> <span className="text-muted f-14">{new Date(val.createdAt).toLocaleDateString()}</span></p>
                                <p className="f-14">{val.content}</p>
                            </div>
                        </li>*/}
                                                </>


                                            )
                                            :
                                            <></>
                                        }

                                        {/* <li>
                                        <div className="graybox">
                                            <p className="f-14">Pre-approval expired</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="graybox">
                                            <p className="f-14">Special offer sent · 1 guest, 11 Oct - 18 Oct</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="graybox">
                                            <p className="f-14">Pre-approval expired</p>
                                        </div>
                                    </li> */}

                                    </ul>

                                </div>
                                <p className="textcounter ms-2"><span>{textcount && textcount}</span>/150</p>
                                <div className="img_chat_upload mb-3 mx-2">
                                    {imageblob && <img src={imageblob}></img>}
                                    </div>
                                <div className="typemsg d-flex align-items-center mx-2">
                                   

                                    <div className="input-group input_grp_with_attach">
                                    <textarea className="form-control noresize" 
                                    
                                    value={message} placeholder="Write a Message" onInput={(e) => edittxt(e)}></textarea>
                                 <div className="input-group-append">
                                  <div className='choosefiles_new'>
                                    <input type="file"
                                    onChange ={(e)=>{setImage(e?.target?.files[0]); setImageblob(URL.createObjectURL(e?.target?.files[0]))}}
                                    ></input>
                                    <button class="btn btn-theme">
                                        <i className="fa fa-chain" />
                                    </button>
                                    </div>
                                    </div>
                                    </div>

                                    <button className={(message) ? "d-block" : "d-block"} onClick={sendMessage}
                                        disabled = {textcount > 150 || message == ""}
                                    >
                                        
                                        <span className="">Send</span></button>
                                    {/* <button className={(message) ? "" : "d-none"} onClick={sendMessage}><span className="fa fa-arrow-right"></span></button> */}
                                </div>
                               
                                

                                </div>


                            </div>
                        </div>
                    </div>
                    :
                    <div className={showcont ? "msgcont mobilecont" : "msgcont"}>
                    <div className="msg_noco">
                        <img src={nochat} className="img-fluid no_chat_img" />
                            {/* <p>No chats available</p> */}
                    </div>
                    </div>
                    }
                    
                    </div>
                    {/* <div  className="col-lg-3 col-md-4 reserve-side" id="reserve">
                        <ReserveDetails img={prof} close={closereserve}/>
                </div> */}
                </div>
            </div>
        </div>

    )
}

export default Inbox