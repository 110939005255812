import React, { Component, useState } from 'react';
import {Modal , Button,Form} from 'react-bootstrap'
import fav from '../../../Assets/images/favicon.png'

import TermsModal from '../Modals/TermsModal'
import WelcomeModal from './WelcomeModal';

const CommitModal = (props) =>{
    const[showcommit , setShowCommit] = useState(true)
    const[showterms , setShowTerms] = useState(false)
    const[showwelcome , setShowWelcome] =useState(false)  //welcome

    const modals = () =>{
        setShowTerms(true)
        document.getElementById("viji").classList.add("d-none")
    }
    const endsign = () => {
        setShowWelcome(true)
        setShowCommit(false)
    }

    return(
  <>
  {showterms && <TermsModal onDismiss={() => setShowTerms(false)} />}
  {showwelcome && <WelcomeModal onDismiss = {() => setShowWelcome(false) }/> }
         <Modal show={showcommit} centered scrollable className='modal_style' id="viji">
          <Modal.Header className='border-0 pb-0'>
            <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
        </Modal.Header>
            <Modal.Body>
            <img src={fav} className="my-3"/>
            <h6 className='mb-3'>Our community commitment</h6>
            <h3>JetToLet is a community where anyone can belong</h3>
            <p>To ensure this, we’re asking you to commit to the following:</p>
            <p>I agree to treat everyone in the JetToLet community – regardless of their race, religion, national origin, ethnicity, skin colour, disability, sex, gender
                 identity, sexual orientation or age – with respect, and without judgement or bias.</p>
            <Button  onClick={() => modals()} variant="link" className='orsnge_y ps-0'>Learn More <span className='fa fa-chevron-right ms-2'></span></Button>

            <Button className='btn btn-theme w-full mb-3' onClick={() =>endsign() }>Agree and Continue</Button>
            <div className='continue-social'>
                <Button className='bnt btnbord w-full' variant="outline-dark" onClick={() =>props.onDismiss()}>Decline</Button>
            </div>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default CommitModal