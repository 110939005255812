import React, { useState, useEffect } from "react";
import "../../Assets/css/landing.css";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import JetToLet from "../../Assets/images/landingPage/flutterpadlogo.png";
import Logo from "../../Assets/images/landingPage/logo.png";
import phoneIcon from "../../Assets/images/landingPage/phone.png";
import carousalBg from "../../Assets/images/landingPage/headCarousal.png";
import liteBg from "../../Assets/images/landingPage/liteBg.png";
import location from "../../Assets/images/landingPage/location-pin.png";
import bannerBg from "../../Assets/images/landingPage/header_bg.png";
import Carousel from "react-bootstrap/Carousel";
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import {
  getUserEmail,
  getUserDatas,
  userLogout,
  getusersdatahooks,
  switchhostandguesthooks,
  getalllandcmsdata,
} from "../../Hooks/useUserHook";
import Login from "../Common/Modals/LoginModal.js";
import { toast, Toaster } from "react-hot-toast";
import { API_URL, toastOptions } from "../../config/env.js";
import liteBgimage from "../../Assets/images/landingPage/liteBg.png";
import { currency } from "../../config/currencycode.js";
import { useDispatch } from "react-redux";
import { USER_CURRENCY } from "../../constant";
import NewNavbar from "./NewNavbar.js";

function AboutHeadSection(props) {
  const [showlog, setShowLog] = useState(false);
  const [email, setEmail] = useState("");
  const [userDatas, setUserDatas] = useState({});
  const [selectedcurrency, setSelectedcurrency] = useState(
    localStorage.getItem("PCURENCY") ? localStorage.getItem("PCURENCY") : "USD"
  );
  const [key, setKey] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cmsdata, setCmsData] = useState([]);

  useEffect(() => {
    const data = getUserEmail();
    setEmail(data);
    setUserDatas(getUserDatas());
    // setLogout()
  }, [showlog]);

  useEffect(() => {
    async function fetchdata() {
      const data = getUserEmail();
      var userdata = await getusersdatahooks(data);
      // setUserDatas(userdata);
    }
    fetchdata();
  }, []);

  useEffect(() => {
    async function fetchdata() {
      const res = await getalllandcmsdata();
      console.log("res cmsdata", res);
      if (res?.data?.type == "success") {
        setCmsData(res?.data?.data);
      }
    }
    fetchdata();
  }, []);

  const cmsFilter = (data) => {
    try {
      let filterdata = cmsdata?.find(
        (el) => el.Identifier == data && el?.status == "active"
      );
      console.log("filterdata_filterdata", filterdata, filterdata?.updatedAt);
      return filterdata;
    } catch (e) {
      console.log("cmsFilter_err", e);
    }
  };

  const logout = () => {
    userLogout().then((data) => {
      console.log("dataslogut", data);
      toast.success(data, toastOptions);
      if (data.status) {
        window.location.reload();
      }
    });
  };
  //   const setCurrency = (data) => {
  //     return dispatch({
  //         type: USER_CURRENCY,
  //         data
  //     })
  // }

  const hostDashboard = async () => {
    if (email) {
      window.location.href =
        window.location.origin + `/host-dashboard/${email}`;
    } else {
      setShowLog(true);
    }
  };

  const checkUserLogin = async (type) => {
    console.log("check user login :", email);
    if (email && email.toLowerCase() != "undefined") {
      if (
        userDatas?.govtproof?.length > 0 &&
        userDatas?.govtproof[0]?.verification == "accepted"
      ) {
        if (parseFloat(userDatas?.hostsubscribedaycount) > 0) {
          if (type == "room") {
            window.location.href = window.location.origin + `/host/${email}`;
          } else if (type == "experience") {
            window.location.href =
              window.location.origin + `/host-experience/${email}`;
          }
        } else {
          toast.error("Need Host Subscription!..");
          window.location.href = window.location.origin + `/account`;
        }
      } else {
        let vrfy =
          userDatas?.govtproof?.length > 0
            ? userDatas?.govtproof[0]?.verification
            : "";
        if (userDatas?.govtproof?.length == 0 || vrfy == "rejected") {
          toast.error("Upload your government id!");
          window.location.href = window.location.origin + `/account`;
        }
        if (vrfy == "pending") {
          toast.error("your government id verification is pending!");
          window.location.href = window.location.origin + `/account`;
        }
      }
    } else {
      setShowLog(true);
    }
  };
  console.log(
    "Images",
    `${API_URL}adminImages/cmsimages/${cmsFilter("land_banner01")?.Image}`
  );
  return (
    <>
      {/* {showlog && <Login logs={key} onDismiss={() => setShowLog(false)} />}

      {cmsdata && cmsdata?.length > 0 && cmsFilter("land_banner01")?.Image && ( */}
      <div
        className="head-section"
        // style={{
        //   backgroundImage: `url(${API_URL}adminImages/cmsimages/${
        //     cmsFilter("land_banner01")?.Image
        //   })`,
        // }}
        style={{ backgroundImage: `url(${bannerBg})` }}
      >
        {/* <NewNavbar /> */}
        <div className="container container_custom head-sec">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-sm-4 mb-4 mb-md-4 mb-lg-0 mb-lg-0 first-head-sec">
              <div className="lite-bg-image">
                <div className="pt-5 left-side-content-section">
                  <div className="content-section">
                    {/* {cmsdata && cmsdata?.length > 0 && (
                      <p className="mb-0">
                        {cmsFilter("land_banner01")?.Title}
                      </p>
                    )} */}
                    <p className="mb-0 about_us_content">
                      {props?.data?.Content}
                      Meet the team creating{" "}
                      <span className="org_txt">dynamic value</span> for
                      <span className="org_txt"> exceptional living.</span>
                    </p>
                  </div>
                  <div className="view-all-properties">
                    {/* <Button
                      variant="primary"
                      onClick={() => {
                        navigate("/landing-map");
                      }}
                    >
                      {cmsFilter("land_banner01")?.Content}
                    </Button> */}
                    <Button variant="primary about_us_content">
                      Meet the team
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default AboutHeadSection;
