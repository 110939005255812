import React, { useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  Form,
  Dropdown,
  Toast,
  TabContainer,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import HostHeader from "../../HostHeader";
import img1 from "../../../../../Assets/images/where/img1.png";
import {
  getHostingDetails,
  getUserRoomListings,
  listSetting,
} from "../../../../../Hooks/useUserHostingHook";
import { API_URL, toastOptions } from "../../../../../config/env";
import { toast, Toaster } from "react-hot-toast";
import { getUserExperienceHostings } from "../../../../../Hooks/useUserExperienceHook";
import ListingModal from "./Modals/ListingModal";

const Listings = () => {
  const location = useLocation();
  console.log("locationnnnnnnnnnnnnnn", location.state);
  const [userRoomListings, setUserRoomListings] = useState([]);
  const [userExperienceListings, setUserExperienceListings] = useState([]);
  const [email, setEmail] = useState("");
  const [reload, setReload] = useState(false);
  const [key, setKey] = useState("");
  const [hostDatas, setHostDatas] = useState([]);
  const [roomdata, setRoomData] = useState({});
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  console.log("key", key);
  const fetchdata = () => {
    const emailid = window.location.pathname.split("/")[3];
    const room = window.location.pathname.split("/")[4];
    console.log("rooommmmmmmmmmmmmm", room, emailid);
    if (room == "experiences") {
      console.log("rooommmmmmmmmmmmmm experiences");
      setKey("experience");
    }
    if (room == "stays") {
      console.log("rooommmmmmmmmmmmmm stays");
      setKey("stays");
    }
    if (!room) {
      setKey("stays");
    }
    setEmail(emailid);
    getUserRoomListings(emailid).then((data) => {
      setUserRoomListings(data);
    });
    getUserExperienceHostings(emailid).then((data) => {
      setUserExperienceListings(data);
    });
  };





  console.log("hostdatasgal", hostDatas)
  useEffect(() => {
    fetchdata();
  }, [reload, !show, show]);

  const images1 = <img src={img1} />;
  const check = <span className="fa fa-check-circle text-success me-1" />;
  const dots = <span className="fa fa-circle text-success me-1" />;
  const hourglass = <span className="fa fa-hourglass text-secondary me-1" />;

  const listsetting = (id, islisted, type) => {
    // listSetting(id, type, !islisted).then(async (data) => {
    //   console.log("toast message :", data.message);
    //   await toast.success(data.message, toastOptions);
    //   setReload(true);
    //   fetchdata();
    // });
  };

  const columns = [
    {
      name: "Listing",
      sortable: true,
      reorder: false,

      cell: (data) => (
        <>
          <Link
            to={`/roomedit/${data?._id}`}
            className="d-flex text-dark nounder align-items-center"
          >
            <img src={API_URL + data.photosPath + "/" + data.photos[0].name} />
            <p>
              <b>{data.title}</b>
            </p>
          </Link>
        </>
      ),
    },
    {
      name: "status",
      reorder: false,

      cell: (data) => (
        <>
          {
            <span
              className={
                data.isApproved
                  ? data.isListed
                    ? "fa fa-check-circle text-success me-1"
                    : "fa fa-circle text-danger me-1"
                  : data.isRejected
                    ? "fa fa-times-circle-o text-danger me-1"
                    : "fa fa-circle text-success me-1"
              }
            />
          }{" "}
          {data.isApproved
            ? data.isListed
              ? "Listed"
              : "Unlisted"
            : data.isRejected
              ? "Rejected"
              : "Pending"}{" "}
        </>
      ),
    },
    {
      name: "List/Unlist",
      button: true,
      reorder: false,

      cell: (data) => (
        <button
          type="button"
          class="btn btn-theme nowrap"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => {
            listsetting(data?._id, data?.isListed, "room");
          }}
        >
          {console.log('data.isListed', data?.isListed)}
          {data?.isListed ? "Unlist" : "List"}
        </button>
      ),
    },
    {
      name: "Instant Booking",
      // selector: (data) => data.instantbook,
      cell: (data) => (
        <>
          {
            <span
              className={
                data.instantBooking
                  ? "fa fa-check-circle text-success me-1"
                  : "fa fa-times-circle-o text-danger me-1"
              }
            />
          }
          {data.instantBooking ? "Yes" : "No"}
        </>
      ),
    },
    {
      name: "Price",
      reorder: false,

      selector: (data) => "$" + data.price,
    },
    // {
    //     name:"beds",
    //     selector: (data) => data.beds
    // },
    // {
    //     name:"baths",
    //     selector: (data) => data.baths
    // },
    {
      name: "Location",
      reorder: false,

      selector: (data) =>
        data.location && data.location.city && data.location.countrycode
          ? data.location.city + "," + data.location.countrycode
          : "",
    },
    {
      name: "Action",
      reorder: false,

      cell: (data) => (
        <span className="list_detail_edit" onClick={() => {handleShow(); setRoomData(data)}}>
          <i className="fa fa-edit" />
        </span>
      ),
    },

  ];

  const expcolumns = [
    {
      name: "Listing",
      sortable: true,
      reorder: false,
      cell: (data) => (
        <>
          <Link
            to={`/experience-edit/${data?._id}`}
            className="d-flex text-dark nounder align-items-center"
          >
            <img src={API_URL + data.photosPath + "/" + data.photos[0].name} />
            <p>
              <b>{data.title}</b>
            </p>
          </Link>
        </>
      ),
    },
    {
      name: "status",
      reorder: false,

      cell: (data) => (
        <>
          {
            <span
              className={
                data.isApproved
                  ? data.isListed
                    ? "fa fa-check-circle text-success me-1"
                    : "fa fa-circle text-danger me-1"
                  : data.isRejected
                    ? "fa fa-times-circle-o text-danger me-1"
                    : "fa fa-circle text-success me-1"
              }
            />
          }{" "}
          {data.isApproved
            ? data.isListed
              ? "Listed"
              : "Unlisted"
            : data.isRejected
              ? "Rejected"
              : "Pending"}{" "}
        </>
      ),
    },
    // {
    //     name:"what you will do",
    //     selector: (data) => data.whatYouWillDo
    // },
    {
      name: "Starting",
      reorder: false,

      selector: (data) => new Date(data.startTime).toLocaleDateString(),
    },
    {
      name: "Ending",
      reorder: false,

      selector: (data) => new Date(data.endTime).toLocaleDateString(),
    },
    {
      name: "List/Unlist",
      reorder: false,

      button: true,
      cell: (data) => (
        <button
          type="button"
          class="btn btn-theme nowrap"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          onClick={() => {
            listsetting(data._id, data.isListed, "experience");
          }}
        >
          {data.isListed ? "Unlist" : "List"}
        </button>
      ),
    },
    // {
    //     name:"Instant Booking",
    //     // selector: (data) => data.instantbook,
    //     cell:(data)=>(<>{<span className={data.instantBooking?'fa fa-check-circle text-success me-1':'fa fa-times-circle-o text-danger me-1'}></span>}{data.instantBooking?"Yes":"No"}
    //     </>)
    // },
    {
      name: "Price per person",
      reorder: false,

      selector: (data) => "$" + data.pricePerGuest,
    },
    // {
    //     name:"beds",
    //     selector: (data) => data.beds
    // },
    // {
    //     name:"baths",
    //     selector: (data) => data.baths
    // },
    {
      name: "Where you will be",
      reorder: false,
      selector: (data) => (data.whereYouWillBe ? data.whereYouWillBe : ""),
    },
    // {
    //     name:"baths",
    //     selector: (data) => data.lastmodification
    // }
  ];

  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HostDetails = [
    {
      name: "Which of these best describes your place?",
    },
    {
      name: "What type of place will guests have?",
    },
    {
      name: "What kind of space will guests have?",
    },
    {
      name: "Tell guest what your place has to offer",
    },
    // {
    //   name: "Where's your place located?",
    // },
    {
      name: "Share some basics about your place",
    },
    {
      name: "Cancellation policy",
    },
    {
      name: "Let guests know what your place has to offer",
    },
    // {
    //   name: "Next, let's add some video & photos of your place",
    // },
    {
      name: "Let's give your place a name",
    },
    {
      name: "Does your place have any of these?",
    },
    {
      name: "Now, let's describe your place in detail",
    },
    {
      name: "Set your price",
    },
    {
      name: "What category is best for your place?",
    },
  ];
  return (
    <div id="listing">
      <HostHeader />
      <div className="container-fluid mb-4 px-4 pt-3">
        <div className="listings">
          {/* <div className='d-flex jc-between my-3'>
                    <h4>{userRoomListings&&userRoomListings.length} Stays Listed</h4>
                    <Button onClick={()=>{navigate(`/host/${email}`)}} variant="outline-secondary"><span className='fa fa-plus'></span> Create listing</Button>
                </div> */}

          {/* <div className='d-flex jc-between my-3'>
                <InputGroup>
                    <InputGroup.Text id="basic-addon1"><span className='fa fa-search'></span></InputGroup.Text>
                    <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"/>
                </InputGroup>
                <div className='d-flex'>
                <div className={drop1 ? "drop-down mx-2 show" : "drop-down mx-2"}>
                            <div className="dropbtn">
                                <button className="btn btn-outline-dark" onClick={() => {setDrop1(!drop1);setDrop2(false)}}>Rooms and Beds</button>
                                <div className="drop-items">
                                    <div className="itemss">
                                        <div className="scrolablediv">
                                            <div className='d-flex jc-between my-3'>
                                                <p>Bedrooms</p>
                                                <div>
                                                    <button className='circlebtn bg-white'>+</button>
                                                    <span className='mx-2'>0</span>
                                                    <button className='circlebtn bg-white'>-</button>
                                                </div>
                                            </div>
                                            <div className='d-flex jc-between my-3'>
                                                <p>Bathrooms</p>
                                                <div>
                                                    <button className='circlebtn bg-white'>+</button>
                                                    <span className='mx-2'>0</span>
                                                    <button className='circlebtn bg-white'>-</button>
                                                </div>
                                            </div>
                                            <div className='d-flex jc-between my-3'>
                                                <p>Beds</p>
                                                <div>
                                                    <button className='circlebtn bg-white'>+</button>
                                                    <span className='mx-2'>0</span>
                                                    <button className='circlebtn bg-white'>-</button>
                                                </div>
                                            </div>
                                   
                                        </div>
                                       
                                        <hr className="themehr" />
                                        <div className="d-flex jc-between">
                                            <Button variant="link" className="text-dark" onClick={() => setDrop1(false)}>Close</Button>
                                            <button className="btn btn-theme">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={drop2 ? "drop-down mx-2 show" : "drop-down mx-2"}>
                            <div className="dropbtn">
                                <button className="btn btn-outline-dark" onClick={() => {setDrop2(!drop2);setDrop1(false)}}>Amenities</button>
                                <div className="drop-items">
                                    <div className="itemss">
                                        <div className="scrolablediv">
                                  
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">

                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    </div>
                                       
                                        <hr className="themehr" />
                                        <div className="d-flex jc-between">
                                            <Button variant="link" className="text-dark" onClick={() => setDrop2(false)}>Close</Button>
                                            <button className="btn btn-theme">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                <div className={drop3 ? "drop-down mx-2 show" : "drop-down mx-2"}>
                    <div className="dropbtn">
                        <button className="btn btn-outline-dark" onClick={() => {setDrop3(!drop3);setDrop1(false);setDrop2(false)}}>Listing status</button>
                        <div className="drop-items">
                            <div className="itemss">
                                <div className="scrolablediv">
                                     <div className='d-grid'>
                                        <div class="form-check my-2 d-inline-block">
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                    <div>
                                                        <p className="hostname f-14">Nightly prices</p>
                                                        <p className="f-12 text muted"></p>
                                                    </div>
                                            </label>
                                            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                        </div>
                                        <div class="form-check my-2 d-inline-block">
    
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                    <div>
                                                        <p className="hostname f-14">Nightly prices</p>
                                                        <p className="f-12 text muted"></p>
                                                    </div>
                                                </label>
        
                                            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                        </div>
                                        <div class="form-check my-2 d-inline-block">

                                            <label class="form-check-label" for="flexRadioDefault2">
                                                    <div>
                                                        <p className="hostname f-14">Nightly prices</p>
                                                        <p className="f-12 text muted"></p>
                                                    </div>
                                                </label>
        
                                            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                        </div>
                                        <div class="form-check my-2 d-inline-block">
    
                                            <label class="form-check-label" for="flexRadioDefault2">
                                                    <div>
                                                        <p className="hostname f-14">Nightly prices</p>
                                                        <p className="f-12 text muted"></p>
                                                    </div>
                                                </label>
        
                                            <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                        </div>
                                     </div>
                                </div>
                                       
                                        <hr className="themehr" />
                                        <div className="d-flex jc-between">
                                            <Button variant="link" className="text-dark" onClick={() => setDrop2(false)}>Close</Button>
                                            <button className="btn btn-theme">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={drop4 ? "drop-down ms-2 show" : "drop-down ms-2"}>
                            <div className="dropbtn">
                                <button className="btn btn-outline-dark" onClick={() => {setDrop4(!drop4);setDrop1(false);setDrop2(false);setDrop3(false)}}>More filters</button>
                                <div className="drop-items">
                                    <div className="itemss">
                                        <div className="scrolablediv">
                                  
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">

                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    <div class="form-check my-2 d-inline-block">
  
                                        <label class="form-check-label" for="flexRadioDefault2">
                                                <div>
                                                    <p className="hostname f-14">Nightly prices</p>
                                                    <p className="f-12 text muted"></p>
                                                </div>
                                            </label>
    
                                        <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault"/>
                                    </div>
                                    </div>
                                       
                                        <hr className="themehr" />
                                        <div className="d-flex jc-between">
                                            <Button variant="link" className="text-dark" onClick={() => setDrop2(false)}>Close</Button>
                                            <button className="btn btn-theme">Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>
                </div> */}
          {console.log("keysdata", key)}
          {key && (
            <TabContainer
              id="left-tabs-example"
              defaultActiveKey={key ? key : "stays"}
            >
              <Row className="d-flex align-items-center justify-content-between flex-column">
                <Col className="mx-auto bg-white mt-4 mb-3">
                  <Nav variant="pills" className="">
                    <Nav.Item>
                      {console.log("userRoomListings", userRoomListings)}
                      <Nav.Link eventKey={"stays"}>
                        {" "}
                        Stays <span>({userRoomListings.length})</span>{" "}
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                                        <Nav.Link eventKey={"experience"}> Experience <span>({userExperienceListings.length})</span></Nav.Link>
                                        
                                    </Nav.Item> */}
                  </Nav>
                </Col>
                <Col className="mx-auto mt-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="stays">
                      <DataTable
                        className="stays_tabe"
                        pagination
                        columns={columns}
                        data={userRoomListings}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="experience">
                      <DataTable
                        className="expr_tabe"
                        pagination
                        columns={expcolumns}
                        data={userExperienceListings}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </TabContainer>
          )}
        </div>
      </div>
      <Toaster />

      {/* list edit modal */}
      {/* <Modal
        show={show}
        onHide={handleClose}
        className="split_modal_popup list_edit_modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {HostDetails && HostDetails?.map((item, index) => {
            return (
              <div className="d-flex align-items-center justify-content-between mb-3">
                <p className="mb-0">{item.name}</p>
                <div>
                  <span className="list_detail_edit">
                    <i className="fa fa-edit" />
                  </span>
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn-theme"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      <ListingModal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
        HostDetails={HostDetails}
        roomdata={roomdata}
        onDismiss={() => {setShow(true)}}
      />
    </div>
  );
};

export default Listings;
