import React, { Component, useEffect, useMemo, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Cookies from "universal-cookie";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { API_URL } from "../../config/env";
import { Link } from "react-router-dom";
import ReactDOMServer from 'react-dom/server';
import {
  getUserEmail,
  getsubscriptiondetailshooks,
  getusersdatahooks,
} from "../../Hooks/useUserHook";
// import { addwishlist , getwishlistarray} from '../../Hooks/filterHook';
import Login from "../Common/Modals/LoginModal.js";
import { getOverAllRating } from "../../Hooks/useUserHostingHook";
import { GrPrevious, GrNext } from "react-icons/gr";

import guest from "../../Assets/images/landingPage/card/guest.png";
import bed from "../../Assets/images/landingPage/card/bed.png";
import bathroom from "../../Assets/images/landingPage/card/bathroom.png";
const cookies = new Cookies();
const Card = (props) => {
  console.log("dsadvhnsavdhasd", props.data);
  // const [wishedlist , setWishedlist] = useState([]);
  const [email, setEmail] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [aaa, setAaa] = useState("");
  const [roomRating, setRoomRating] = useState({});
  const [userdata, setUserdata] = useState({});
  const [photos, setPhotos] = useState([]);
  const [isWishlisted, setWishlisted] = useState("fav-btn fa fa-heart-o");
  const [wishref, setWishref] = useState(1);
  const [percentage, setPercentage] = useState(0);

  const [guestcount, setGuestCount] = useState(0);
  const [bedcount, setBedCount] = useState(0)
  const [bedroomcount, setBedroomCount] = useState(0)
  const handleFav = async () => {
    const mail = getUserEmail();
    console.log("fskdfksadf", mail);
    //     // console.log("handlefav:",email,"mail",mail);
    //     if(mail){
    //      // var imageid = props.data._id
    // // console.log("props :" , props);
    // let payload = {
    //     roomid : props.data._id,
    //     emailid : await mail
    // }
    // // console.log("payload" , payload);
    // await addwishlist(payload)
    //  .then((data) => { console.log("wishlist r eturn: " , data);
    //  setWishedlist(data);
    //  alert("wishlist updated!");
    // })
    //  .catch((e) => console.log("wishlist return error" , e))
    //    }else{
    //     setLoginModal(true);
    //    }
    let finddata = props?.userData?.wishList?.find(
      (item) => item == props.data._id
    )
      ? "remove"
      : "add";
    console.log("email ", mail);
    if (mail) {
      let updt = await props.wishlistarray(
        props.data._id,
        mail,
        props.data.title,
        finddata
      );
      getusersdatahooks(mail);
    } else {
      setLoginModal(true);
    }
  };
  console.log("wishref", wishref);

  const renderPhotos = (_photos, _btnClass) => {
    console.log(
      "dsadhgsahgdas REnder PHOTOS : ",
      props.data._id,
      _btnClass,
      isWishlisted,
      _photos[0]?.isWishlisted
    );
    return _photos.map((j) => singlePhoto(j, _btnClass));
  };

  const singlePhoto = (j, _btnClass) => {
    console.log("dsadhgsahgdas Single Photo : ", j, _btnClass);
    return (
      <div class="item">
        {/* <Link to={"/room/"+props.data._id}> */}
        {/* {console.log("API_URL+props.data.photosPath+/j.name",`${API_URL}${props.data.photosPath}/${j.name}`)} */}
        <img src={`${API_URL}${props.data.photosPath}/${j.name}`} />
        {/* <p className="name themeclr fw-600 f-14">{userdata?.wishList?.includes(props.data._id)}   {`data :: ${BtnClass} : props : ${props.data._id} :: REsulr : ${userdata?.wishList?.includes(props.data._id)} :: New  ; ${isWishlisted} :: photots ${j?.isWishlisted} `}</p> */}
        {/* <button onClick={handleFav} className={(email&&props.data._id.includes(props.userData.wishList))?'fav-btn fa fa-heart':'fav-btn fa fa-heart-o'}></button> */}
        {/* <button onClick={handleFav} className={(props.userData.wishList.includes(props.data._id))?'fav-btn fa fa-heart':'fav-btn fa fa-heart-o'}></button> */}
        <button onClick={handleFav} className={_btnClass} />

        {/* </Link> */}
      </div>
    );
  };

  useEffect(() => {
    // getsubscriptiondetailshooks
    async function fetchData() {
      console.log("getsubscriptiondata");
      let data = await getsubscriptiondetailshooks();
      console.log("getsubscriptiondata", data?.record);
      let rec = data?.record?.find((el) => el["type"] == "Guest");
      console.log("getsubscriptiondetailshooks recdata", rec);
      let percentage = rec["offerpercentage"];
      console.log("percentage data", percentage);
      setPercentage(percentage);
    }
    fetchData();
  }, []);

  useEffect(() => {
    console.log("dsadhgsahgdas props?.userData", props);
    // const newPhotos = props?.data?.photos.map(v => ({...v, isWishlisted: props?.userData?.wishList?.find((item) => item == props?.data._id) ? "fav-btn fa fa-heart" : "fav-btn fa fa-heart-o"}));
    // setTimeout(()=>{
    // setUserdata(props?.userData)
    // setPhotos(newPhotos)
    // setWishlisted(props?.userData?.wishList?.find((item) => item == props?.data._id) ? "fav-btn fa fa-heart" : "fav-btn fa fa-heart-o")
    setWishref((wish) => wish + 1);
    // } , 1000);
  }, [props]);

  // useEffect(()=>{
  //   renderPhotos(photos,isWishlisted)
  // },[photos,isWishlisted])

  useEffect(() => {
    console.log("initial UseEffect");
    (async () => {
      // props.wishdata()
      // setWishedlist(props.userData.wishList);
      const mail = await getUserEmail();
      // const wishlistarray = await getwishlistarray(mail);
      //         setWishedlist(wishlistarray);

      //         console.log("wishlist array" , wishlistarray.data.record[0]);
      if (mail) {
        setEmail(mail);

        // wishdata();
      }
    })();
  }, []);

  useEffect(() => {
    if (props.data._id) {
      getOverAllRating(props.data._id).then((data) => {
        setRoomRating(data.data);
      });
    }
  }, [props.data._id]);

  const BtnClass = useMemo(() => {
    console.log(
      "dsadhgsahgdas prop id",
      props.data._id,
      userdata?.wishList?.some((item) => item == props.data._id)
    );
    //    if(email){
    if (userdata?.wishList?.find((item) => item == props.data._id)) {
      return "fav-btn fa fa-heart";
    } else {
      console.log("False");
      return "fav-btn fa fa-heart-o";
    }
    //    }
  }, [email, userdata]);

  useEffect(() => {
    //Card previous arrow
    const prevEle = React.createElement(GrPrevious);
    const prevIcon = ReactDOMServer.renderToStaticMarkup(prevEle);
    const prev = document.querySelectorAll("[aria-label='Previous']");
    prev.forEach((element) => {
      element.innerHTML = prevIcon;
    });

    //Card previous arrow
    const nextEle = React.createElement(GrNext);
    const nextIcon = ReactDOMServer.renderToStaticMarkup(nextEle);
    const next = document.querySelectorAll("[aria-label='Next']");
    next.forEach((element) => {
      element.innerHTML = nextIcon;
    });
  });


  useEffect(() => {
if(props?.data?.floorPlan?.length > 0) {
  console.log('guessssssssss', props?.data?.floorPlan)
  let guestdata = props?.data?.floorPlan.filter((el) => el?.type == 'guest');
  let bedroomdata = props?.data?.floorPlan.filter((el) => el?.type == 'Bedroom ');
  let beddata = props?.data?.floorPlan.filter((el) => el?.type == 'Bathroom');

  console.log('guest data', guestdata, 'bedroom data', bedroomdata, 'bed data', beddata);
  if(guestdata?.length > 0) {
    setGuestCount(guestdata[0].count);
  }
  if(beddata?.length > 0) {
    setBedCount(beddata[0].count);
  }
  if(bedroomdata?.length > 0) {
    setBedroomCount(bedroomdata[0].count);
  }

}
  }, [props?.data?.floorPlan])
  
  return (
    <div className="grid-5 mt-4 cus_card">
      {loginModal && (
        <Login
          logs={"login"}
          onDismiss={() => {
            setLoginModal(false);
          }}
        />
      )}
      {
        // BtnClass &&
        <div className="where-card yellownav">
          <div className="card-img">
            <OwlCarousel
              className="owl-theme"
              dots={false}
              margin={10}
              nav={true}
              items={1}
              id="big"
            >
              {props?.data?.photos &&
                props?.data?.photos.map((j) => {
                  if (j?.name?.split(".")[1] != "mp4") {
                    return (
                      <div class="item">
                        <Link
                          target="_blank"
                          to={"/room/" + props.data._id}
                          stat
                        >
                          <img
                            src={`${API_URL}${props.data.photosPath}/${j.name}`}
                          />
                        </Link>
                        <button
                          onClick={handleFav}
                          className={
                            props?.userData?.wishList?.find(
                              (item) => item == props.data._id
                            )
                              ? "fav-btn fa fa-heart"
                              : "fav-btn fa fa-heart-o"
                          }
                        />
                      </div>
                    );
                  }
                })}
            </OwlCarousel>
          </div>

          <Link
            target="_blank"
            to={"/room/" + props.data._id}
            className="nounder"
          >
            <div className="where-cont px-3 py-3">
              <div className="d-flex justify-content-between">
                <p className="name themeclr fw-600 f-14">{props.data.title}</p>
                {/* <p className="fav txtgray f-12">
                  New
                  <span className="fa fa-star ms-1" />
                </p> */}
              </div>
              {/* <p className='review'><i className='fa fa-star me-1'><span className='fa fa-star'></span></i>{roomRating&&roomRating.overAllReview&&(roomRating.overAllReview).toFixed(1) +"("+roomRating?.users+")"}</p> */}
            {(props.data.firstName != undefined && props.data.firstName != "undefined" && props.data.firstName != "") ? <p className="txtgray f-12 cus_txt_clr">

                Hosted by {props.data.firstName} {props.data.lastName}
              </p> :
               <p className="txtgray f-12 cus_txt_clr">

               Hosted by JetToLet
             </p>}
              {/* <p className="date themeclr fw-600 card-date-custom f-12">12 Jul</p> */}

              {/* <p className="rent themeclr f-14 fw-600 card-amount-dis my-2 my-md-2">
                {props?.symbol} {props.data.price} / per Day
              </p>
              <p className="txtgray f-12 cus_txt_clr mb-0">Membership</p>
              <p className="rent themeclr f-14 fw-600 card-amount-dis mb-0">
                {Number(props.data.price) -
                  (Number(props?.data?.price) * Number(percentage)) / 100}
              </p> */}
              <div className="mt-3 mb-2">
                <p className="member-price">
                  ${Math.ceil(Number(props.data.price) -
                  (Number(props?.data?.price) * Number(percentage)) / 100)}
                  <span className="member">/month Member-Price</span>
                </p>
                <p className="non-member-price">
                  ${(Number(props.data.price)) }
                  <span className="member">/month Non-Member Price</span>
                </p>
              </div>
              {/* <div className="rent d-flex align-items-center justify-content-between themeclr f-14 fw-600 card-amount-dis my-2 my-md-2">
                <span>
                  {props?.symbol} {props.data.price} / per Day
                </span>
                <div>
                  <p className="txtgray f-12 cus_txt_clr mb-0">Membership</p>
                  <p className="rent themeclr f-14 fw-600 card-amount-dis mb-0">
                    10 %
                  </p>
                </div>
              </div> */}

              <hr className="m-0 cus_hr " />
              <div className="card_ftr_sec mt-2">
                <div className="d-flex align-items-center ">
                  <img
                    src={guest}
                    alt="guest"
                    className="me-2 img-fluid cmn_logo"
                  />
                  {console.log('props?.data?.floorPlan', props?.data?.floorPlan)}
                  <span>{guestcount} {guestcount <= 1? "guest" : "guests"}</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bed}
                    alt="bed"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{bedcount} {bedcount <= 1? "bed" : "beds"}</span>
                </div>
                <div className="d-flex align-items-center ">
                  <img
                    src={bathroom}
                    alt="bathroom"
                    className="me-2 img-fluid cmn_logo"
                  />
                  <span>{bedroomcount} {bedroomcount <= 1? "bathroom" : "bathrooms"}</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      }
    </div>
  );
};

export default Card;
